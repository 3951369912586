import axios from 'axios'

import config from '../config'
import { getAuth0Token } from '@outlier-org/lst-api-client-react'

export {
  putApi,
  postApi,
  getToken,
  getApi
}

async function getToken () {
  return await getAuth0Token()
}

const axiosInstance = axios.create({
  baseURL: config.apiHost
})

const defaultHeaders = {
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'X-React-App-Git-Commit': process?.env?.REACT_APP_GIT_COMMIT,
  Pragma: 'no-cache',
  Expires: 0
}

// We might have to remove baseurl from instance and add params like isAuthenticated
// This will be updated as per the requirement
async function getApi (url, config = {}, queryParams = {}) {
  const token = await getToken()
  return axiosInstance.get(url, {
    baseURL: config.apiHost,
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...defaultHeaders
    },
    params: queryParams
  })
}

async function postApi (url, config = {}, body = {}) {
  const token = await getToken()
  return axiosInstance.post(url, body, {
    baseURL: config.apiHost,
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...defaultHeaders
    }
  })
}

async function putApi (url, config = {}, body = {}) {
  const token = await getToken()
  return axiosInstance.put(url, body, {
    baseURL: config.apiHost,
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...defaultHeaders
    }
  })
}
