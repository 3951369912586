import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import queryString from 'query-string'
import PageTitle from '../PageTitle/PageTitle'
import {
  CostText,
  CardText,
  ThisSelectionText,
  PageWrapper,
  ButtonsWrap,
  ExceedText,
  ConfirmButton,
  PageContent,
  BottomWrap,
  BreadCrumbComponent,
  AddtokenText,
  ScheduleReviewContainer
} from './style'
import TokensAndCart from '../CourseCatalog/TokensAndCart'
import ExceedIcon from '../../assets/icons/exceedIcon.svg'
import RemoveModal from '../CartSideBarPanel/RemoveConfirmationModal'
import ReviewCard from './ReviewCard'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import {
  getMonthDayFromDate,
  toDateString,
  getDateStringWithYearLongMonth
} from '../../utilities/dateTime'
import { AppContext } from '../ContextProvider/ContextProvider'
import AddTokensButton from '../AddTokens/AddTokensButton'
import {
  getActiveTokens,
  getGroupedRelationshipTokens,
  getNonRegularTokens,
  getFilteredTokens
} from '../../utilities/tokenUtils'
import config from '../../config'
import api from '../../api'
import { addNewCourse } from '../../utilities/course'
import { isConsultant } from '../../utilities/user'
import { useUserStore } from '@outlier-org/lst-auth-react'

const ScheduleReview = ({ history, location }) => {
  const assignTokensCalled = useRef(false)
  const [deleted, setDeleted] = useState(false)
  const [removeData, setRemoveData] = useState()
  const [removeModal, setRemoveModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [enrollmentloading, setEnrollmentLoading] = useState(false)
  const user = useUserStore(state => state.user)
  const {
    courses: studentCourses,
    tokens,
    studentData,
    isContextLoading,
    setStudentCourses,
    setStudentAttempts,
    setTokens
  } = useContext(AppContext)

  const { instrideId } = studentData || {}
  const availableTokens = getActiveTokens(tokens)
  const relationshipTokens = getNonRegularTokens(availableTokens)
  const groupedRelationshipTokens = getGroupedRelationshipTokens(relationshipTokens)

  useEffect(
    () => {
      if (deleted) {
        setDeleted(false)
      }
    },
    // eslint-disable-next-line
    [deleted]
  )
  const cartItems = useMemo(
    () => JSON.parse(localStorage.getItem('userCart')) || [],
    // eslint-disable-next-line
    [deleted]
  )

  const courses = cartItems || []

  const sortedCourses = [...courses]
    .sort((a, b) => new Date(a?.dateStart) - new Date(b?.dateStart))
    .sort((a, b) => b?.status?.localeCompare(a?.status))
    .sort((a, b) => a?.duration - b?.duration)
    .sort((a, b) => a?.name?.localeCompare(b.name))

  useEffect(() => {
    const autoAssignTokens = async () => {
      const notEnoughTokens = availableTokens?.length < sortedCourses?.length
      if (isContextLoading ||
        enrollmentloading ||
        !sortedCourses?.length ||
        notEnoughTokens ||
        !location
      ) return

      const { assignTokens } = queryString.parse(location.search)
      if (!assignTokens || assignTokensCalled.current) return
      onConfirm(true)
    }

    autoAssignTokens()
    // eslint-disable-next-line
  }, [
    isContextLoading,
    enrollmentloading,
    location,
    availableTokens,
    sortedCourses,
    history
  ])

  const onRemove = (index) => {
    cartItems.splice(index, 1)
    localStorage.setItem('userCart', JSON.stringify(cartItems))
    setDeleted(true)
  }

  const commitment = () => {
    const reducer = (previousValue, currentValue) => {
      return previousValue + currentValue
    }
    if (!courses.length) return null
    const course = courses
      ?.map((item) => {
        let durations = 0
        if (item.duration === '7') {
          durations = 20
        } else {
          durations = 10
        }
        return durations
      })
      .reduce(reducer)
    return course
  }

  const onConfirm = async (assignTokensHasBeenCalled) => {
    const allAreRelnshipTokens = availableTokens.length === relationshipTokens.length
    const hasMultiRelnships = Object.keys(groupedRelationshipTokens).length > 1

    const directToRelationshipAssignment = !instrideId &&
      (hasMultiRelnships || (!allAreRelnshipTokens && relationshipTokens.length))
    if (directToRelationshipAssignment) {
      return history.push({
        pathname: '/review-schedule/relationship-assignment',
        state: {
          relationshipTokens,
          availableTokens,
          sortedCourses,
          isDirectedFromScheduleReview: true
        }
      })
    }

    assignTokensCalled.current = assignTokensHasBeenCalled
    const body = sortedCourses.map((item) => ({
      cohortId: item?.cohortId,
      courseId: item?.id
    }))

    setEnrollmentLoading(true)

    try {
      const data = await api.assignTokens(body)
      localStorage.removeItem('userCart')
      if (data) {
        addNewCourse(body)
        const [
          studentCourses,
          studentTokens,
          studentAttempts
        ] = await Promise.all([
          api.getStudentCourses(isConsultant(user?.email)),
          api.getAllTokens({ activityLog: config.showTokenActivityLog() }),
          api.getStudentAttempts()
        ])

        setStudentCourses(studentCourses)
        setTokens(getFilteredTokens(studentTokens))
        setStudentAttempts(studentAttempts)

        history.push('/confirmation')
      }
    } catch (e) {
      console.log({ e })
    }

    setEnrollmentLoading(false)
  }

  const overlapCourse = studentCourses?.find(
    (item) =>
      sortedCourses?.findIndex(
        (i) => i?.dateStart === item?.cohort?.dateStart
      ) > -1
  )

  const enoughTokens = availableTokens?.length < sortedCourses?.length
  const tokeNeeded = Math.abs(sortedCourses.length - availableTokens.length)
  const plurals = (ln) => ln > 1 ? 's' : ''

  const showTokensInfo = !instrideId
  const showAddTokensButton = enoughTokens && !instrideId
  const disableSignupButton = (enoughTokens || !sortedCourses?.length) && !instrideId

  if (isContextLoading || enrollmentloading) return <LoadingSpinner />
  return (
    <ScheduleReviewContainer>
      <BreadCrumbComponent path='/catalog' title='Catalog' />
      <PageWrapper data-testid='page-wrapper'>
        <PageTitle
          title='Review details'
          className='flex-container title-wrap'
        >
          <TokensAndCart
            backToPageName='Review Details'
            tokens={availableTokens?.length || 0}
            hideBag
          />
        </PageTitle>
        <PageContent data-testid='page-content'>
          {sortedCourses?.length
            ? sortedCourses?.map((item) => (
              <ReviewCard
                data={item}
                onRemove={(id) => {
                  setRemoveModal(!removeModal)
                  setRemoveData(item.name)
                }}
                onUpdate={() => setDeleted(true)}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
                key={item.id}
              />
            ))
            : null}
          <BottomWrap isEdit={isEdit}>
            {overlapCourse ? (
              <ThisSelectionText>
                This selection overlaps with courses you’ve already scheduled:{' '}
                {overlapCourse?.name} (
                {getMonthDayFromDate(
                  toDateString(overlapCourse?.cohort?.dateStart),
                  '',
                  'short'
                )}{' '}
                -{' '}
                {getDateStringWithYearLongMonth(
                  overlapCourse?.cohort?.finalExamEndTime, 'short'
                )}
                , {overlapCourse?.cohort?.duration === 14 ? '10' : '20'}hr/wk
                commitment)
              </ThisSelectionText>
            ) : null}
            <CardText data-testid='card-text' className='margin-bottom'>
              Total commitment: {commitment()}hr/wk{' '}
              {commitment() > 40 && <img src={ExceedIcon} alt='' />}
            </CardText>
            {commitment() > 40 && (
              <ExceedText>
                Your commitment exceeds our 40hr/wk maximum recommendation.
              </ExceedText>
            )}
            {showTokensInfo && (
              <>
                <CostText data-testid='copy-text'>
                  Cost: {courses?.length} Token{plurals(courses.length)}
                </CostText>
                {enoughTokens && (
                  <AddtokenText>
                    Add {tokeNeeded}{' '} token{plurals(tokeNeeded)} to confirm
                  </AddtokenText>
                )}
              </>
            )}
            <ButtonsWrap data-testid='buttons-wrap' className='margin-top'>
              {showAddTokensButton && (
                <AddTokensButton
                  availableTokens={availableTokens.length}
                  coursesCount={sortedCourses.length}
                  tokensCount={Math.abs(
                    sortedCourses.length - availableTokens.length
                  )}
                  backToPageName='Review Details'
                  redirectToPath='/review-schedule'
                  type='button'
                />
              )}

              <ConfirmButton
                className='complete-signup'
                onClick={onConfirm}
                disabled={disableSignupButton}
              >
                Complete Signup
              </ConfirmButton>
            </ButtonsWrap>
          </BottomWrap>
        </PageContent>
        <RemoveModal
          show={removeModal}
          onHide={() => setRemoveModal(false)}
          onRemove={(id) => onRemove(id)}
          courseName={removeData}
        />
      </PageWrapper>
    </ScheduleReviewContainer>
  )
}

export default ScheduleReview
