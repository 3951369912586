import React, { useState, useContext, useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import Modal from 'react-bootstrap/Modal'
import useSegmentEvents from '../../hooks/segmentEvents/useSegmentEvents'
import { COURSE_REGISTRATION_PAGEVIEW } from '../../Constants/eventType'
import { AppContext } from '../ContextProvider/ContextProvider'
import config from '../../config'
import TokensAndCart from './TokensAndCart'
import Courses from './Courses'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { FAILURE } from '../ErrorMessage/errorType'
import PreviouslyScheduled from './PreviouslyScheduled'
import { GlobalStyle } from '../Pretest/style'
import { PrimaryButton } from '../Pretest/QuestionComponent/style'
import { getActiveTokens } from '../../utilities/tokenUtils'
import {
  OverrideStyles,
  PageWrapper,
  BreadCrumbComponent,
  PageTitleComponent,
  PageContentComponent
} from './style'
import {
  isSegmentEventInLS,
  removeSegmentEventFromLS,
  setFiredSegmentEventsInLS,
  shouldRedirectToRecommendedCourses
} from '../../utilities/gguDegreeUtils'
import { RECOMMENDED_COURSES_PATH } from '../../Constants/gguEnrollmentUrls'

const CourseCatalog = ({
  history,
  location,
  setCurrentPage,
  courseQuantity,
  currentCourseNumber
}) => {
  const { sendEvent } = useSegmentEvents()
  const { search = {} } = useLocation()
  const { replace } = qs.parse(search, { ignoreQueryPrefix: true })
  const params = qs.parse(window.location.search?.slice(1))
  const isRegistrationRedesign = params?.gguEnrollmentV2 === 'true'
  const {
    showFailure,
    outlierCourses,
    activeCourses,
    studentData,
    courses,
    tokens,
    prospects,
    isActiveGGUStudent,
    isContextLoading,
    isGGUStudentEnrolled,
    showGGUEnrollment,
    isGGUDegreeDataLoading,
    canStudentEnrollInUPJCourses
  } = useContext(AppContext)
  const { instrideId } = studentData || {}

  const redirectToRecommendedCourses =
    shouldRedirectToRecommendedCourses(showGGUEnrollment, prospects)

  if (redirectToRecommendedCourses && !replace) {
    history.push(RECOMMENDED_COURSES_PATH)
  }

  const { state: { pretestComplete, courseName } = {} } = location || {}
  const [showPretestModal, setShowPretestModal] = useState(pretestComplete)
  const availableTokens = useMemo(() => {
    return getActiveTokens(tokens)
  }, [tokens])

  const segmentEvent = {
    eventName: COURSE_REGISTRATION_PAGEVIEW,
    pageNumber: isRegistrationRedesign
      ? `4-${(currentCourseNumber * 2) + courseQuantity}` : '4-2'
  }

  useEffect(() => {
    if (!replace || replace === 'undefined') return
    if (isSegmentEventInLS(segmentEvent)) return

    removeSegmentEventFromLS({
      eventName: COURSE_REGISTRATION_PAGEVIEW,
      pageNumber: `4-${currentCourseNumber + 1}`
    })
    const properties = isRegistrationRedesign
      ? {
        page_name: `Course Catalog Course ${currentCourseNumber}`,
        page_number: `4-${(currentCourseNumber * 2) + courseQuantity}`
      } : {
        page_name: 'Course Catalog',
        course: outlierCourses?.find(course => course?.id === replace)?.displayName || '',
        time_stamp: Date.now()
      }
    sendEvent({ eventName: COURSE_REGISTRATION_PAGEVIEW, properties })

    setFiredSegmentEventsInLS(segmentEvent)
    // eslint-disable-next-line
  }, [replace])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isContextLoading, showFailure])

  const showGGUCatalog = !canStudentEnrollInUPJCourses

  if (showFailure) return <ErrorMessage errorType={FAILURE} />
  if (isContextLoading || (isActiveGGUStudent && isGGUDegreeDataLoading)) return <LoadingSpinner />

  return (
    <PageWrapper
      $hasPracExerciseFlag={config.hasPracExerciseFlag}
      isGGUStudentView={showGGUCatalog}
    >
      {showGGUCatalog && <OverrideStyles />}
      {!isRegistrationRedesign && (
        <BreadCrumbComponent
          path={showGGUEnrollment && !isGGUStudentEnrolled ? '/recommended-courses' : '/'}
          title={showGGUEnrollment && !isGGUStudentEnrolled ? 'Review Schedule' : 'Dashboard'}
        />
      )}
      <PageTitleComponent
        title={showGGUCatalog ? 'Degree Courses' : 'Course Catalog'}
        className='flex-container'
        isGGUStudentView={showGGUCatalog}
      >
        {!showGGUCatalog &&
          <TokensAndCart
            backToPageName='Catalog'
            tokens={availableTokens?.length || 0}
            showAvailableTokens={!!instrideId}
          />}
      </PageTitleComponent>
      {(activeCourses?.length > 0 || showGGUCatalog) && (
        <PageContentComponent>
          <div data-testid='active-courses'>
            <Courses
              isGGUStudentView={showGGUCatalog}
              outlierCourses={outlierCourses}
              list={activeCourses}
              courses={courses}
              history={history}
              setCurrentPage={setCurrentPage}
              currentCourseNumber={currentCourseNumber}
              courseQuantity={courseQuantity}

            />
          </div>
          {!showGGUCatalog && (
            <div data-testid='previouslyScheduled'>
              <PreviouslyScheduled
                activeCourses={activeCourses}
                courses={courses}
                history={history}
              />
            </div>
          )}
        </PageContentComponent>
      )}
      <Modal show={showPretestModal} centered>
        <GlobalStyle />
        <Modal.Body>
          <p>
            You've passed the {courseName} pretest! Take the course for credit or see
            our other {courseName === 'Calculus I' ? 'math ' : ''}courses in the Catalog.
          </p>
          <PrimaryButton
            data-testid='btn-awesome'
            onClick={() => setShowPretestModal(false)}
            height='49px'
          >
            awesome
          </PrimaryButton>
        </Modal.Body>
      </Modal>
    </PageWrapper>
  )
}

CourseCatalog.displayName = 'CourseCatalog'
export default CourseCatalog
