import React, { useState } from 'react'
import {
  getCohortClosestExam,
  getCohortOpenExamDates,
  getCohortStartSecondsSinceEpoch,
  getLatestCohortClosedExam
} from '../../utilities/cohort'
import {
  daysToSeconds,
  getTimezoneShort,
  secondsToDateString,
  secondsToFormattedDateTimeShort
} from '../../utilities/dateTime'
import { DAYS_BEFORE_EXAM } from '../../Constants'
import WidgetState from './WidgetState'
import config from '../../config'
import { Separator } from './style'
import has from 'lodash/has'
import { PROGRESS_TYPE } from '../../Constants/courseCard'
import { getToken } from '../../utilities/axios'
import {
  getProctorioCourseHomeUrl,
  getProctorioExamTag,
  getProctorioExamTake
} from '../../utilities/proctorio'
import { getLatestCohort } from '../../utilities/course'
import api from '../../api'
import ExtensionState from './ExtensionState'
import {
  getExamFromExamNumber,
  getExamReviewChapter
} from '../../utilities/chapterUtils'
import { useUserStore } from '@outlier-org/lst-auth-react'

const ExamCard = ({ course, studentProgress, examsArray }) => {
  const [dismissedExam, setDismissedExam] = useState(false)
  const user = useUserStore((state) => state.user) || {}
  const { email: studentEmail } = user

  let { id, cohort, chapters } = course
  cohort = cohort[0] || cohort

  let extensionData = null

  const currentTime = Date.now()
  const examOpenData = getCohortOpenExamDates(cohort, currentTime)
  const { startDate, examNumber, isFinalExam } = getCohortClosestExam(cohort)
  const upComingExam = getExamFromExamNumber({
    chapters,
    examNumber,
    isFinalExam
  })

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const formattedDate = startDate &&
    secondsToFormattedDateTimeShort(startDate).replace(', ', ' at ')
  const timezone = getTimezoneShort(secondsToDateString(startDate))

  const getUpcomingExamNote = () => {
    if (!formattedDate || !upComingExam) return
    let { title, isFinalExam } = upComingExam
    title = isFinalExam ? `The ${title}` : title
    return `${title} will open on ${formattedDate} ${timezone}.`
  }

  const getOpenExamNote = (exam, lockDate) => {
    let { title, isFinalExam } = exam
    title = isFinalExam ? `The ${title}` : title
    const lockDateInSeconds = lockDate / 1000
    const formattedLockDate =
      secondsToFormattedDateTimeShort(lockDateInSeconds).replace(', ', ' at ')
    const timezone = getTimezoneShort(secondsToDateString(lockDateInSeconds))
    return `${title} is open until ${formattedLockDate} ${timezone}.`
  }

  const getUpcomingStudyGuideNote = () => {
    if (!upComingExam) return
    const containsStudyGuide = has(upComingExam, 'examStudyGuide.file.url')
    if (!containsStudyGuide) return ''
    const studyGuideOpenDate = startDate - daysToSeconds(DAYS_BEFORE_EXAM)
    const formattedStudyGuideDate =
      secondsToFormattedDateTimeShort(studyGuideOpenDate)
        .replace(', ', ' at ')
    return `The study guide will become available on ${formattedStudyGuideDate}
    ${timezone}. In the meantime,`
  }

  let stateData
  if (upComingExam && !examOpenData) {
    const upcomingStudyGuideNote = getUpcomingStudyGuideNote()
    const examReviewChapter = getExamReviewChapter(upComingExam, course.chapters)
    const reviewFirstSectionUUID = examReviewChapter?.sections?.[0]?.['section_uuid']

    let description = getUpcomingExamNote()
    if (reviewFirstSectionUUID) {
      description += ' The review chapter is available now.'
    }

    if (!reviewFirstSectionUUID) {
      description += ` ${upcomingStudyGuideNote} 
      ${upcomingStudyGuideNote === '' ? 'V' : 'v'}isit the Course Home to begin reviewing the content.`
    }

    stateData = {
      description,
      dataTestId: 'midterm-unavailable',
      ...(reviewFirstSectionUUID
        ? {
          secondaryBtnTxt: 'Study',
          secondaryBtnFunction: () => {
            window.location.href = `${courseBaseUrl}/#/${reviewFirstSectionUUID}`
          }
        }
        : {
          primaryBtnTxt: 'Visit home',
          primaryBtnFunction: () => { window.location.href = courseBaseUrl }
        })
    }
  }

  const handleStartButtonClick = async (exam) => {
    const proctorioUrls = await getProctorioUrls(exam)
    if (!proctorioUrls) return

    const { launchUrl } = proctorioUrls

    const token = await getToken()

    const url = getProctorioCourseHomeUrl(courseBaseUrl, token, launchUrl)
    window.location.href = url
  }

  const getProctorioUrls = async (exam) => {
    const cohort = getLatestCohort(course)
    const { name: cohortName } = cohort
    const startDate = getCohortStartSecondsSinceEpoch(cohort)
    const examTake = getProctorioExamTake(
      courseBaseUrl,
      exam.chapter_uuid,
      exam.exams.Question[0].Question_uuid
    )

    const examTag = getProctorioExamTag(cohortName, exam, startDate)
    const proctorioUrls =
      await api.getProctorioUrls(examTake, examTag, course.id)

    return proctorioUrls
  }

  const openExamStudyGuide = async (url, chapterId) => {
    if (!url || !chapterId) return
    await api.incrementStudyGuideClicks(id, chapterId)
    window.open(url, '_blank')
  }

  const handleExamOpen = (exam, lockDate) => {
    const { examStudyGuide, chapter_uuid: chapterId } = exam
    const examReviewChapter = getExamReviewChapter(exam, course.chapters)
    const reviewFirstSectionUUID = examReviewChapter?.sections?.[0]?.['section_uuid']

    const { file: { url } = {} } = examStudyGuide || {}
    const isStudyGuideOpen = !!url

    return {
      description: `${getOpenExamNote(exam, lockDate)} Start the exam now or
      ${isStudyGuideOpen || reviewFirstSectionUUID
        ? 'take some time to study.'
        : 'visit the Course Home to begin reviewing the content.'
      }`,
      dataTestId: 'exam-open',
      primaryBtnTxt: 'Start',
      primaryBtnFunction: () => { handleStartButtonClick(exam) },
      secondaryBtnTxt: isStudyGuideOpen || reviewFirstSectionUUID ? 'Study' : 'Visit home',
      secondaryBtnFunction: reviewFirstSectionUUID
        ? () => {
          window.location.href = `${courseBaseUrl}/#/${reviewFirstSectionUUID}`
        }
        : isStudyGuideOpen
          ? () => { openExamStudyGuide(url, chapterId) }
          : () => { window.location.href = courseBaseUrl }
    }
  }

  const { EXAM_COMPLETE, EXAM_PERCENTAGE } = PROGRESS_TYPE

  const handleExamCompleted = (exam) => {
    let {
      title,
      isFinalExam,
      chapter_uuid: chapterUuid,
      exams: { Question: questions }
    } = exam

    const { Question_uuid: questionUuid } = questions[0]
    const examUrl = `${courseBaseUrl}/#/${chapterUuid}/${questionUuid}`
    const examPercentage = studentProgress[EXAM_PERCENTAGE]
    const percentage = examPercentage[chapterUuid]
    title = isFinalExam ? `the ${title}` : title
    return {
      description: `You completed ${title} and scored ${percentage}%.
      If you’d like, you can review your answers to learn from them.`,
      dataTestId: 'exam-complete',
      secondaryBtnTxt: 'Review',
      secondaryBtnFunction: () => { window.location.href = examUrl }
    }
  }

  const dismissedExams =
    localStorage.getItem(`${studentEmail}_dismissed_exams`)
      ? JSON.parse(
        localStorage.getItem(`${studentEmail}_dismissed_exams`)
      )
      : []

  const dismissExam = (chapterUuid) => {
    setDismissedExam(true)
    if (!dismissedExams.includes(chapterUuid)) {
      dismissedExams.push(chapterUuid)
    }
    localStorage.setItem(
      `${studentEmail}_dismissed_exams`,
      JSON.stringify(dismissedExams)
    )
  }

  const handleExamClosed = (exam) => {
    if (!exam) return

    let { title, chapter_uuid: chapterUuid, isFinalExam } = exam
    title = isFinalExam ? `the ${title}` : 'exam'

    const { courseInfoUrl, isPartnerCohort } = cohort
    const resourcesPage = config.courseBaseUrlById(id) + '/#/resources'
    const resourcesUrl = isPartnerCohort ? courseInfoUrl : resourcesPage

    return {
      description: (
        <>
        You did not submit {title} before its deadline and received
        a grade of 0%. <a href={resourcesUrl}>View the Toolkit</a> for
        more information on assessment windows.
        </>
      ),
      dataTestId: 'exam-closed',
      secondaryBtnTxt: 'Dismiss',
      secondaryBtnFunction: () => { dismissExam(chapterUuid) }
    }
  }

  if (examOpenData) {
    const { examOpen, lockDate, isFinalExam } = examOpenData
    const currentExam = getExamFromExamNumber({
      chapters: examsArray,
      examNumber: examOpen,
      isFinalExam
    })
    if (!currentExam) return null

    const { chapter_uuid: chapterUuid } = currentExam

    // Check if exam completed or not
    const examComplete = studentProgress[EXAM_COMPLETE]
    const isExamCompleted = chapterUuid in examComplete

    /* If exam is completed it renders exam completed card
      else it renders exam open card */
    extensionData = {
      exam: currentExam,
      course: course,
      type: 'exam'
    }
    stateData = isExamCompleted
      ? handleExamCompleted(currentExam)
      : handleExamOpen(currentExam, lockDate)
  } else {
    const latestClosedExam = getLatestCohortClosedExam(cohort)
    if (latestClosedExam) {
      const { closedExam, isFinalExam } = latestClosedExam
      const currentClosedExam = getExamFromExamNumber({
        chapters: examsArray,
        examNumber: closedExam,
        isFinalExam
      })

      if (currentClosedExam) {
        const {
          chapter_uuid: chapterUuid
        } = currentClosedExam

        const examComplete = studentProgress[EXAM_COMPLETE]
        const isExamInCompleted = !(chapterUuid in examComplete)
        const isExamDismissed = dismissedExams.includes(chapterUuid)
        const showExamClosedCard =
          isExamInCompleted && !isExamDismissed && !dismissedExam

        stateData = showExamClosedCard && handleExamClosed(currentClosedExam)
      }
    }
  }

  if (!stateData) return null
  const { dataTestId } = stateData

  return (
    <>
      <Separator />
      <div data-testid={dataTestId}>
        <WidgetState
          {...stateData}
        />
      </div>
      {extensionData &&
        <ExtensionState
          {...extensionData}
        />}
    </>
  )
}

export default ExamCard
