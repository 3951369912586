import styled, { createGlobalStyle } from 'styled-components'
import media from '../../../../mediaQueries'
import { PrimaryButton } from '../../../Buttons/style'
import { LinkStyles, TextLink } from '../../../Links/style'

export const Container = styled.div`
   margin-top: 88px;
   margin-left: 103px;
   margin-right: 103px;

   ${media.mobile`
      margin-left: 32px;
      margin-right: 32px;
   `}

   ${media.smallMobile`
      margin-left: 24px;
      margin-right: 24px;
   `}

`

export const BackLink = styled.div`
 display: flex;
 align-items: center;
 letter-spacing: 1px;
  ${LinkStyles}
 > span {
     margin-left: 16px;
     display: inline-block;
 }

 > img {
     width: 16px;
     height: 16px;
 }
`

export const PageTitle = styled.h2`
 margin-top: 32px;
 font-weight: 300;
 font-size: 36px;
 line-height: 43px;
`

export const CourseDescription = styled.div`
 font-weight: 400;
 font-size: 18px;
 line-height: 21px;
 border-bottom: 1px solid rgb(255 255 255 / 30%);
 padding-bottom: 32px;
 margin-top: 16px;
 margin-bottom: 32px;

 > span {
    float: right;
    padding: 3px 12px;
    background-color: #25272B;
    font-size: 14px;
    color: #B1BFC5;
    text-transform: capitalize;
 }

 img {
     margin: 0 6px;
     &:first-child {
         ${media.smallMobile`
            display: none;
         `}
     }
 }

 > div {
     display: inline-block;
     font-size: 16px;
     line-height: 19px;
     color: #B1BFC5;

    ${media.smallMobile`
        margin-top: 8px;
    `}
 }
`
export const AvailableOptionsSection = styled.div`
 margin-top: 26px;
 font-weight: 400;
 line-height: 21px;

 > h3 {
    font-size: 18px;
 }

 > p {
    margin: 24px 0 0 0;
    font-size: 18px;
    font-weight: 300;
    width: 584px;

    ${media.mobile`
        width: 100%;
    `}
 } 

 a {
   text-underline-offset: 4.5px;
   text-decoration: underline;
   ${TextLink}
 }
`
export const AvailableOptions = styled.div`
  margin-top: 24px; 
  display: flex;
  flex-direction: row;
  gap: 32px;

  ${media.tablet`
   flex-direction: column;
  `}
`
export const AvailableOption = styled.div`
 background: #1D1D1F;
 width: 31.4%;
 ${({ isPartnership }) => isPartnership && `
   width: 50%;
   `}
 ${({ grow }) => grow && 'width: 50%;'}
 border-radius: 5px;
 padding: 24px;

 ${media.desktop`
    width: 31%;

   ${({ grow }) => grow && 'width: 50%;'}
 `}

 ${media.tablet`
   width: 100%;
    ${({ grow }) => grow && 'gap: 24px'}
    &:last-child {
       margin:0;
    }
  `}
`
export const ExpiredOptions = styled.div`
  margin-top: 24px; 
  display: flex;
  flex-direction: row;
  gap: 32px;


  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    gap: 24px;
  `}
`
export const UpcomingOptions = styled.div`
  margin-top: 24px; 
  display: flex;
  flex-direction: row;
  gap: 32px;

  ${media.tablet`
   flex-direction: column;
  `}
`

export const ExpiredOption = styled.div`
 background: #1D1D1F;
 border-radius: 5px;
 padding: 24px;

 ${media.desktop`
    width: 100%;
 `}
`

export const UpcomingOption = styled.div`
 background: #1D1D1F;
 width: 31.4%;
 ${({ isPartnership }) => isPartnership && `
   width: 50%;
 `}
 border-radius: 5px;
 padding: 24px;

 ${media.desktop`
    width: 31%;
 `}

 ${media.tablet`
   width: 100%;
    &:last-child {
       margin:0;
    }
  `}
`

export const OptionHeader = styled.h3`
 font-size: 24px;
 font-weight: 300;
 line-height: 29px;
 letter-spacing: 0em;
 display: flex;
 align-items: center;
 img {
    margin-right: 12px;
 }
`
export const OptionDescription = styled.p`
 margin: 24px 0 0 0;
 font-size: 18px;
 line-height: 21px;
 font-weight: 300;

`

export const OptionAvailability = styled.p`
 font-size: 16px;
 line-height: 19px;
 font-weight: 400;
 margin: 24px 0 0 0;
`

export const Button = styled(PrimaryButton)`
 margin-top: 24px;
 ${({ $fixedwidth }) => $fixedwidth ? `
   width: 140px;
   display: inline-block;
 ` : `
    width: auto; 
    display: inline;
 `}
`
export const UpcomingOptionsSection = styled(AvailableOptionsSection)`
 margin-top: 48px;
`
const card = `
 background-color: #1D1D1F;
 border-radius: 5px;
 padding: 24px; 
`
export const ExeptionRequestContainer = styled.div`
 ${card}
 max-width: 833px;
 display: flex;

 ${media.tablet`
   flex-flow: column;
 `}

 h3 {
   font-size: 24px;
   line-height: 28px; 
   font-style: normal;
   min-width: 177px;
   margin-right: 24px;
   margin-bottom: 12px;
   font-weight: 300;
 }

 p {
    font-size: 18px;
    line-height: 21px; 
    font-weight: 300;

    span {
       margin-bottom: 9px;
       display: inline-block;
    }
 }

 a, #link {
    text-decoration: none;
    display: inline-block;
    ${LinkStyles}
 }
`
export const NoExitOptionsContainer = styled(ExeptionRequestContainer)`
   display: inline-block;
   a {
      font-size: 18px; 
      line-height: 21px; 
      font-weight: 300;
      text-transform: none;
      text-decoration: underline;
   }
`

// Warning Modal

export const GlobalStyle = createGlobalStyle`
  .modal-dialog {
    max-width: none;
  }
  
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-body {
    padding: 24px;
    & > p {
      margin-bottom: 24px;
    }
  }

  .modal-content {
    background-color: #161618;
    width: 600px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    ${media.mobile`
      width: 525px;
    `}
  
    ${media.smallMobile`
      width: 324px;
    `}
  
    ${media.foldable`
      width: 250px;
    `}
  }
`

export const ExitModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const Separator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;

  ${media.mobile`
    flex-direction: column;

    & > button:first-child {
      margin-bottom: 10px;
    }
  `}

`

export const ClickableText = styled.span`
  color: #5FC4B8;
  text-decoration: underline;
  cursor: pointer;
`

export const ClickableLink = styled.a`
  color: #5FC4B8;
  text-decoration: underline;
  cursor: pointer;
`

export const SurveyText = styled.span`
  display: block;
  margin-top: 24px;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SurveyLink = styled.span`
  color: #5FC4B8;
  text-decoration: underline;
  cursor: pointer;
`
