import styled, { createGlobalStyle } from 'styled-components'
import { Card } from 'react-bootstrap'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import media from '../../../mediaQueries'

export const OverrideCSSProperties = createGlobalStyle`
  .tooltip.show {
    opacity: 1;
  }
`

export const CardWrapper = styled(Card)`
  background-color: #1D1D1F;
  padding: 24px 12px;
  height: 100%;
`

export const Link = styled.a`
  color: #77B1AF!important;
  font-weight: 400;
  
  &:hover {
      text-decoration: none;
      opacity: 0.5
  }
`

export const PageOverview = styled.div`
  margin-top: 24px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  white-space: pre-wrap;
`

export const SectionOverview = styled(PageOverview)`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 36px;
`

export const BoldParagraph = styled.p`
  font-weight: 400;
  margin-bottom: 24px;
`

export const BoldText = styled.span`
  font-weight: 400;
`

export const PageContent = styled.div`
  margin: 24px 1rem 1rem 1rem;
`
export const RejectionMessage = styled.div`
  display: flex;

  img {
    width: 20px;
    height: 20px;
    margin-right: 13px;
    position: relative;
    top: 2px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #E1774F;
    margin-bottom: 0;
  }
`

export const FormWrapper = styled.div`
  margin-top: 20px;
  height: 700px;

  @media (max-width: 767px) {
    height: 88vh;
  }
`

export const BackToLink = styled(BackBreadcrumb)`
  margin-left: 85px;

  ${media.mobile`
    margin-left: 0;
  `}
`

export const ExitFormPageContainer = styled.div` 
  & > div.content { 
    max-width: 600px;
    margin: 16px auto;

    ${media.mobile`
      margin: 16px 24px;
      max-width: 512px;
    `}

    ${media.smallMobile`
      margin: 16px 24px;
    `}

    ${media.foldable`
      margin: 16px 24px;
      max-width: 250px;
    `}

    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;

  > p {
        font-size: 18px;
        margin-top: 34px;
        margin-bottom: 0;
      }

    a {
      font-size: 18px;
      font-weight: 400;
      text-decoration: underline;
    }
  }
`

export const TransferCohortHeader = styled.div`
  display: flex;
  flex-direction: column;

  & div.course-details {
    display: flex;
    margin-top: 8px;
  }

  & p {
    margin-bottom: 0;

    &:nth-child(2) {
      margin: -6px 6px;
    }

    &:nth-child(3) {
      margin-top: 2px;
    }

    &.course {
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
    }

    &.status {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #B1BFC5;
    }
  }
`

export const TransferContent = styled.div`
  display: flex;
  flex-direction: column;

  & div.cohort-selection {
    display: flex;
    margin-top: 32px;
    margin-bottom: 40px;

    ${media.smallMobile`
      flex-direction: column;
    `}

    & > div:first-child {
      margin-right: 24px;

      ${media.smallMobile`
        margin-bottom: 24px;
      `}
    }

    & span[data-testid='tooltip'] {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      top: 2px;
      margin-left: 12px;
      background-image: url(images/icons/tooltip-i.svg);
    }
  }

  & p {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    &.field-label {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #98A4AE;

      &.start-date {
        margin-bottom: 10px;
      }
    }
  }

  & a {
    margin-top: 24px;
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`

export const ReviewCohort = styled.div`
  display: flex;

  ${media.mobile`
    flex-direction: column;
  `}
`

export const CohortDetailsContainer = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  border: 1px solid ${({ newCohort }) => newCohort ? '#FFFFFF' : 'rgba(255, 255, 255, 0.3)'};

  &:first-child {
    ${media.mobile`    
      margin-bottom: 0;
    `}
  }

  & p {
    margin-bottom: 0;
    margin-top: 6px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: ${({ newCohort }) => newCohort ? '#FFFFFF' : '#B1BFC5'};

    &.title {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-top: 0;
    }
  }
`

export const FormDetailWrapper = styled.div`
  font-size: 18px;
  margin-top: 34px;
  margin-bottom: 0;
`
