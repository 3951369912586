import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { getTranscripts } from '../../utilities/accountArea'
import { ItemContainer } from './style'
import { GGU_TRANSCRIPTS_LINK, PITTSBURGH_TRANSCRIPTS_LINK } from '../../Constants/accountArea'
import TranscriptsList from './TranscriptsList'
import { useUserStore } from '@outlier-org/lst-auth-react'

function TranscriptsPage () {
  const {
    studentAttempts, isContextLoading
  } = useContext(AppContext) || {}
  const user = useUserStore((state) => state.user) || {}
  const { email } = user
  const [transcripts, setTranscripts] = useState(null)

  useEffect(() => {
    if (!studentAttempts?.length) return

    const getAllTranscripts = async () => {
      const result = await getTranscripts(studentAttempts, email)
      setTranscripts(result)
    }

    getAllTranscripts()

    // eslint-disable-next-line
  }, [studentAttempts])

  const gguTranscripts = useMemo(() => {
    if (!transcripts?.ggu?.length) return null
    return transcripts?.ggu

    // eslint-disable-next-line
  }, [transcripts])

  const showGGUTranscripts = useMemo(() => {
    if (!gguTranscripts?.length) return false

    return gguTranscripts.some(transcript => transcript.showTranscript)

    // eslint-disable-next-line
  }, [gguTranscripts])

  const pittTranscripts = useMemo(() => {
    if (!transcripts?.pittsburgh?.length) return null
    return transcripts?.pittsburgh

    // eslint-disable-next-line
  }, [transcripts])

  const showPittTranscripts = useMemo(() => {
    if (!pittTranscripts?.length) return false

    return pittTranscripts.some(transcript => transcript.showTranscript)

    // eslint-disable-next-line
  }, [pittTranscripts])

  const excludedTranscripts = useMemo(() => {
    if (!transcripts?.excluded?.length) return null
    return transcripts?.excluded

    // eslint-disable-next-line
  }, [transcripts])

  if (isContextLoading) return <LoadingSpinner />

  const noTranscripts = !pittTranscripts?.length &&
    !gguTranscripts?.length && !excludedTranscripts?.length

  return (
    <div
      data-testid='transcripts-page'
    >
      <ItemContainer>
        {pittTranscripts?.length > 0 && (
          <TranscriptsList
            transcripts={pittTranscripts}
            showTranscripts={showPittTranscripts}
            title='University of Pittsburgh Transcript'
            transcriptsLink={PITTSBURGH_TRANSCRIPTS_LINK}
          />
        )}
        {gguTranscripts?.length > 0 && (
          <TranscriptsList
            transcripts={gguTranscripts}
            showTranscripts={showGGUTranscripts}
            title='Golden Gate University Transcript'
            transcriptsLink={GGU_TRANSCRIPTS_LINK}
          />
        )}
        {excludedTranscripts?.length > 0 && (
          <TranscriptsList
            transcripts={excludedTranscripts}
            showTranscripts
            title='Courses excluded from your transcripts'
            description='Transcripts exclude courses taken as audit or with other the schools of record.'
          />
        )}
        {noTranscripts && (
          <TranscriptsList
            transcripts={[]}
            showTranscripts={false}
            title='Credit Transfer'
          />
        )}
      </ItemContainer>
    </div>
  )
}

export default TranscriptsPage
