import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import get from 'lodash/get'
import {
  CustomTitle,
  CourseItemContainer,
  CourseItemHeader,
  EditButton,
  CourseItemDescription,
  NoCoursesText,
  StyledCourseStatusText,
  StyledDot,
  Link
} from './style'
import { ACCOUNT_PATH, CONTENT } from '../../Constants/accountArea'
import { getMonthDayFromDate } from '../../utilities/dateTime'
import {
  getLatestCohort,
  getCourseAssets,
  isStudioCohort,
  filterOutGGUCourses
} from '../../utilities/course'
import {
  getStudentStatus,
  getUpComingDeadline,
  isUpcomingOrCurrentCohort
} from '../../utilities/courseManagementUtils'
import api from '../../api'
import Dot from '../../assets/icons/dot-icon.svg'
import { AppContext } from '../ContextProvider/ContextProvider'
import { AMAZON_RELATIONSHIP, GGU_RELATIONSHIP, GUILD_RELATIONSHIP } from '../../Constants/relationships'
import config from '../../config'
import { isConsultant } from '../../utilities/user'
import { isEmpty } from 'lodash'
import {
  getCourseOrderInCertificate,
  getCertificateWithPartnerDetails,
  getFirstCertificateCohort
} from '../../utilities/certificate'
import { OFFER_NOT_ACCEPTED } from '../../Constants/prospectStatus'
import { useUserStore } from '@outlier-org/lst-auth-react'

function CourseManagement () {
  const user = useUserStore((state) => state.user) || {}
  const { email } = user
  const { certificates, catalogCourses, prospects } = useContext(AppContext)
  const [activeCourses, setActiveCourses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { isCollegeSuccessCourse } = config
  const history = useHistory()
  useEffect(() => {
    const getStudentCourses = async () => {
      setIsLoading(true)
      const { courses = [] } = await api.getStudentCourses(isConsultant(email))
      setIsLoading(false)

      const prospectStatus = prospects?.[0]?.prospectStatus
      const isOfferNotAccepted = prospectStatus === OFFER_NOT_ACCEPTED
      const filteredCourses = isOfferNotAccepted
        ? filterOutGGUCourses(courses, prospectStatus)
        : courses

      const studentCourses = filteredCourses.reduce((validCourses, course) => {
        const latestCohort = getLatestCohort(course)
        const isCollegeSuccess = isCollegeSuccessCourse(course?.id)

        const isValidCourse = !((isEmpty(latestCohort) || !latestCohort) ||
          (!isCollegeSuccess && !isUpcomingOrCurrentCohort(latestCohort)))
        if (!isValidCourse) return validCourses

        validCourses.push({ ...course, latestCohort })
        return validCourses
      }, [])

      setActiveCourses(studentCourses)
    }
    getStudentCourses()
    // eslint-disable-next-line
  }, [])

  const CourseItem = ({
    name,
    iconSVG,
    studentStatus,
    startDate,
    endDate,
    cohortDuration,
    finalWithdrawalDate,
    finalDropDate,
    isPartnership,
    isStudio,
    isGGU,
    isGuild,
    showGuildExitOptions,
    isAmazon,
    isProfCert
  }) => {
    const isStudioStatus = isPartnership && isStudio
    const cohortDurationText = isStudioStatus
      ? 'Studio'
      : cohortDuration ? `${cohortDuration} Weeks` : ''
    const hasCohortDates = startDate && endDate
    const cohortDatesText = hasCohortDates ? `${startDate} - ${endDate}` : ''

    const hasStudentStatusAndCohortDates = studentStatus && cohortDatesText
    const hasCohortDatesAndDuration = cohortDatesText && cohortDurationText

    return (
      <CourseItemContainer data-testid='courseItem'>
        <CourseItemHeader>
          <div>
            <img src={iconSVG} alt={name} /> <span>{name}</span>
          </div>

          <EditButton
            onClick={() => history.push(
              `/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.COURSE_MANAGEMENT_PATH}/${name}`
            )}
            hideOnMob
          >MANAGE COURSE
          </EditButton>
        </CourseItemHeader>

        <CourseItemDescription>
          <StyledCourseStatusText>
            {studentStatus}
            {hasStudentStatusAndCohortDates
              ? <StyledDot src={Dot} alt='dot' />
              : null}
            {cohortDatesText}
            {hasCohortDatesAndDuration ? <StyledDot src={Dot} alt='dot' /> : ''}
            {cohortDurationText}
          </StyledCourseStatusText>
          <p>
            {!isStudioStatus && getUpComingDeadline(
              {
                finalWithdrawalDate,
                finalDropDate,
                studentStatus,
                isAmazon,
                isGuild,
                showGuildExitOptions,
                isGGU,
                isPartnership,
                isProfCert
              }
            )}
          </p>
          <EditButton
            showOnMob
            onClick={() => history.push(
              `/${CONTENT.ACCOUNT}/${ACCOUNT_PATH.COURSE_MANAGEMENT_PATH}/${name}`
            )}
          >
            MANAGE COURSE
          </EditButton>
        </CourseItemDescription>
      </CourseItemContainer>
    )
  }

  const goToCatalog = () => (
    history.push('/catalog')
  )

  if (isLoading) return <LoadingSpinner />

  return (
    <>
      <CustomTitle>
             Active courses
      </CustomTitle>
      <div style={{ marginTop: 2 }}>
        {activeCourses?.length ? activeCourses.map(course => {
          const { latestCohort, displayName, id, profCert } = course
          const {
            id: cohortId,
            dateStart,
            duration,
            finalWithdrawalDate,
            finalDropDate,
            finalExamEndTime,
            studentStatus,
            isPartnerCohort,
            relationship
          } = latestCohort
          const relationshipType = get(latestCohort, 'relationship.fields.relationshipType', [])

          const isPartnership = isPartnerCohort || relationshipType.includes('partnership')
          const partnerName = relationship?.fields?.relationshipName
          const isAmazon = isPartnership && partnerName === AMAZON_RELATIONSHIP
          const isGGU = isPartnership && partnerName === GGU_RELATIONSHIP
          const isStudio = isStudioCohort(course)
          const { courseIcon } = getCourseAssets(catalogCourses, id)

          const isGuild = isPartnership && partnerName === GUILD_RELATIONSHIP
          let showGuildExitOptions = false

          if (isGuild) {
            const certificateWithPartnerDetails = getCertificateWithPartnerDetails({
              certificates, courses: activeCourses, relationshipName: partnerName
            })
            const courseOrder = getCourseOrderInCertificate({
              certificate: certificateWithPartnerDetails, courseId: id, cohortId
            })
            const firstCourseCohort = getFirstCertificateCohort(certificateWithPartnerDetails)
            const firstCourseWithdrawalDate = firstCourseCohort?.finalWithdrawalDate
            showGuildExitOptions = courseOrder > 0 &&
              new Date() > new Date(firstCourseWithdrawalDate)
          }

          return (
            <CourseItem
              name={displayName}
              key={id}
              iconSVG={courseIcon}
              startDate={getMonthDayFromDate(dateStart)}
              endDate={getMonthDayFromDate(finalExamEndTime?.split('T')[0])}
              cohortDuration={duration}
              finalWithdrawalDate={finalWithdrawalDate}
              finalDropDate={finalDropDate}
              studentStatus={getStudentStatus(studentStatus)}
              isPartnership={isPartnership}
              isStudio={isStudio}
              isAmazon={isAmazon}
              isGGU={isGGU}
              isGuild={isGuild}
              showGuildExitOptions={showGuildExitOptions}
              isProfCert={profCert}
            />
          )
        }
        ) : (
          <NoCoursesText>
          You don’t have any active courses.
            <Link
              onClick={goToCatalog}
            >
            View Catalog
            </Link>
          </NoCoursesText>
        )}
      </div>
    </>
  )
}

export default CourseManagement
