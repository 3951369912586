import React from 'react'
import config from '../../../../config'
import { CHAPTER_TYPE } from '../../../../Constants/courseCard'
import WhatsNextWidget from '../WhatsNextWidget'
import Assignment from './Assignment'
import Exam from './Exam'

function Quiz ({
  course,
  visitedQuizData,
  chapterUuid,
  chapterNo,
  sectionNo,
  courseProgress,
  desktopHero
}) {
  const { id, chapters } = course

  const { quizNo } = visitedQuizData

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const handleNextSection = ({
    nextChapterNo,
    nextSectionNo,
    nextSectionUrl
  }) => {
    return (
      <div data-testid='quiz-completed'>
        <WhatsNextWidget
          description={`You completed Quiz ${quizNo} of Section ${chapterNo}.${sectionNo}
      Quizzes. Continue to Section
       ${nextChapterNo}.${nextSectionNo}.`}
          primaryButtonText='Continue'
          primaryButtonFunction={() => {
            window.location.href = nextSectionUrl
          }}
          desktopHero={desktopHero}
        />
      </div>
    )
  }

  const handleChapterByType = (nextChapter) => {
    const { CHAPTER, EXAM, WRITING_ASSIGNMENT_CHAPTER_RECORD } = CHAPTER_TYPE
    switch (nextChapter.type) {
      case CHAPTER: {
        const nextSection = nextChapter.sections[0]
        const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
        return handleNextSection({
          nextChapterNo: chapterNo + 1,
          nextSectionNo: 1,
          nextSectionUrl
        })
      }
      case EXAM: {
        return (
          <Exam
            course={course}
            courseProgress={courseProgress}
            desktopHero={desktopHero}
          />
        )
      }
      case WRITING_ASSIGNMENT_CHAPTER_RECORD: {
        return (
          <Assignment
            course={course}
            courseProgress={courseProgress}
            desktopHero={desktopHero}
          />
        )
      }
      default:
        return null
    }
  }

  // when last activity is quiz and completed
  const handleCompletedQuiz = () => {
    const chapterIndex = chapters.findIndex(
      (c) => c.chapter_uuid === chapterUuid
    )
    const chapter = chapters[chapterIndex]
    if (sectionNo < chapter.sections?.length) {
      const nextSection = chapter.sections[sectionNo]
      const nextSectionUrl = `${courseBaseUrl}/#/${nextSection.section_uuid}`
      return handleNextSection({
        nextChapterNo: chapterNo,
        nextSectionNo: sectionNo + 1,
        nextSectionUrl
      })
    } else if (chapterIndex < chapters.length - 1) {
      const nextChapter = chapters[chapterIndex + 1]
      return handleChapterByType(nextChapter)
    }
    return null
  }

  return handleCompletedQuiz()
}

Quiz.displayName = 'Quiz'
export default Quiz
