import React from 'react'
import WhatsNextWidget from '../WhatsNextWidget'
import {
  getNextAvailableSection,
  getVisitedPracticeExerciseInfo
} from '../../../../utilities/section'
import config from '../../../../config'

function PracticeExercises ({
  course,
  courseProgress,
  chapterNo,
  chapterUuid,
  sectionNo,
  desktopHero
}) {
  const { id } = course
  const { studentProgress, sectionData } = courseProgress
  const {
    studentAnswers,
    'practice-exercises-complete': practiceExercisesComplete
  } = studentProgress

  const { section_uuid: sectionUuid, section_exe: sectionExe } = sectionData
  const { practice_exercises: practiceExercises } = sectionExe

  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(id)

  const isFirstSection = chapterNo === 1 && sectionNo === 1
  const visitedPracticeExercises = getVisitedPracticeExerciseInfo(
    studentAnswers,
    practiceExercises,
    isFirstSection
  )

  const {
    title,
    id: practiceExerciseId,
    remaining,
    visited,
    completed
  } = visitedPracticeExercises

  const sectionUrl = `${courseBaseUrl}/#/${sectionUuid}`
  const problemSetUrl = `${sectionUrl}/${practiceExerciseId}`

  const isMultipleVisitedQuestions = visited > 1 ? 's' : ''
  const isMultipleRemainingQuestions = remaining > 1 ? 's' : ''

  const isSectionPracticeExercisesComplete =
    practiceExercisesComplete[sectionUuid]

  const sectionNumber = `Section ${chapterNo}.${sectionNo}`
  const chapterInfo = {
    chapterUuid,
    chapterNo,
    sectionNo
  }

  const getNextActivity = () => {
    const {
      practice_terms: practiceTerms,
      practice_exercises: practiceExercises,
      quiz
    } = sectionExe

    // Check practice exercises section is completed
    if (!isSectionPracticeExercisesComplete && practiceExercises) { return `${sectionNumber} Practice Exercises` }

    if (practiceTerms) return `${sectionNumber} Practice Terms`
    if (quiz) return `${sectionNumber} Quizzes`

    if (isSectionPracticeExercisesComplete) {
      const { nextChapterNo, nextSectionNo } = getNextAvailableSection(
        course,
        chapterInfo
      )

      return `Section ${nextChapterNo}.${nextSectionNo}`
    }
  }

  // Practice Exercises - In progress
  let stateData = {
    dataTestId: 'practice-exercises-in-progress',
    description: `You completed ${visited}
     question${isMultipleVisitedQuestions} in ${title} of ${sectionNumber}
      Practice Exercises. Continue to complete the remaining ${remaining}
       question${isMultipleRemainingQuestions}.`,
    primaryButtonText: 'Continue',
    primaryButtonFunction: () => {
      window.location.href = problemSetUrl
    }
  }

  // Practice Exercises - Completed
  if (completed) {
    let primaryCTAUrl = sectionUrl
    if (isSectionPracticeExercisesComplete) {
      const { nextSectionUrl } = getNextAvailableSection(course, chapterInfo)
      primaryCTAUrl = nextSectionUrl
    }

    stateData = {
      dataTestId: 'practice-exercises-completed',
      description: `You completed ${title} of ${sectionNumber} Practice Exercises. Continue to ${getNextActivity()}`,
      primaryButtonText: 'Continue',
      primaryButtonFunction: () => {
        window.location.href = primaryCTAUrl
      }
    }
  }

  return (
    <div data-testid={stateData.dataTestId}>
      <WhatsNextWidget {...stateData} desktopHero={desktopHero} />
    </div>
  )
}

PracticeExercises.displayName = 'PracticeExercises'
export default PracticeExercises
