import React, { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import ActivitiesDetailHeader from '../ActivitiesDetailHeader/ActivitiesDetailHeader'

import {
  CourseDetailWrap,
  Divider,
  MessageCard,
  ActivityListWrap,
  SectionHeader,
  ActivityList,
  ActivityListItem,
  StyledP,
  MoreButton
} from './styles'
import { ChecklistIcon } from '../CourseListCard/styles'
import { WHITE, YELLOW, RED } from '../checklistColors'
import { getFormattedSchedule } from '../../../utilities/cohortScheduleUtils'
import { INTENSIVE_COHORT_DURATION } from '../../../Constants/cohort'
import { AppContext } from '../../ContextProvider/ContextProvider'
import {
  completedSection,
  createSectionList,
  filterClickedSection,
  getActiveLearningInstructors,
  getFormattedSections,
  getIntensiveSchedules,
  getLectureInstructors,
  getNextWeekSection,
  getPreviousLastWeekSection,
  getRecommendedProgress,
  getRecommendedProgressWeek,
  getSectionTypeSchedules,
  previousSections,
  removeAlreadyAnimatedActivity
} from './CourseActivitiesUtils'
import { QUIZ, SECTION, SectionType } from '../../../Constants/sectionType'
import config from '../../../config'
import ActivityDropdown from './ActivityDropdown'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import api from '../../../api'
import {
  EXAM_COMPLETE,
  STUDENT_ANSWERS
} from '../../../Constants/studentProgressKeys'
import { BREAK, ESSAY, EXAM } from '../../../Constants/examType'
import { checkCourseNames, getLatestCohort, isStudioCohort } from '../../../utilities/course'
import { getIsVIP, isUserAuditor } from '../../../utilities/user'
import { useUserStore } from '@outlier-org/lst-auth-react'

const renderCard = () => (
  <MessageCard data-testid='lockCard' marginBottom='24px'>
    <div style={{ flex: 1 }}>
      <h4>Start with Guesswork first.</h4>
      <p>
        Completing Guesswork will unlock the other activities in the section.
      </p>
    </div>
    <ChecklistIcon
      width='32px'
      height='32px'
      color='#B1BFC5'
      marginRight='0px'
      src='images/icons/icon-lock.svg'
    />
  </MessageCard>
)

const assessmentEndDate = (endDate, isActiveAssessment, assessmentColor) => {
  const warningLabel = assessmentColor === WHITE ? 'Open now!' : 'Closing soon!'
  return (
    <>
      {endDate && (
        <p>
          {endDate}{isActiveAssessment && <span>{warningLabel}</span>}
        </p>
      )}
    </>
  )
}

const RenderActivities = ({
  examRetakes,
  courseUnits,
  isVIP,
  isVIPGradedContent,
  isStudioCohort,
  dynamicCohortSchedule,
  chapters,
  courseId,
  latestCohort,
  toggleCheckList,
  allSectionsData,
  filteredSchedules,
  isIntensive,
  studentData = {},
  hasCohortStarted
}) => {
  const [isShowAll, setIsShowAll] = useState(false)
  const [toggleState, setToggleState] = useState(false)
  const [textAnimation, setTextAnimation] = useState(false)
  const [activityTitle, setActivityTitle] = useState('')
  const sectionList = createSectionList({
    courseUnits,
    isVIP,
    isVIPGradedContent,
    isStudioCohort,
    examRetakes,
    sectionsData: allSectionsData,
    dynamicCohortSchedule,
    chapters,
    filteredSchedules,
    isIntensive,
    studentData,
    courseId,
    latestCohort
  })
  const { startDate, endDate, title, warningColor } = dynamicCohortSchedule
  const isAudit = isUserAuditor(latestCohort)

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setTextAnimation(true)
      setToggleState(true)
    }, 1000)

    return () => {
      clearTimeout(showTimeout)
    }
  }, [])
  if (!sectionList?.length) {
    return null
  }
  const hasGuesswork = sectionList[0]?.title === '1.1 Guesswork'
  const showGuessworkCard = hasGuesswork && !sectionList[0].isCompleted && !isAudit
  const lastClickedActivity = JSON.parse(
    localStorage.getItem('lastClickedActivity')
  )
  const sectionCompleted = completedSection(sectionList, lastClickedActivity)
  const sectionLists = sectionList
    ?.filter(section => filterClickedSection(section, lastClickedActivity))
    ?.slice(0, isShowAll ? sectionList?.length : 8)
  if (!sectionLists?.length) { return null }
  const courseUrl = chapters?.length
    ? config.courseBaseUrlById(courseId)
    : null

  const onActivityClick = (
    lockText,
    title,
    type,
    sectionUUID,
    exerciseId,
    hashLink,
    currentActivity,
    isCalculusActiveLearning,
    isCalculusLecture
  ) => {
    if (lockText || type === BREAK) return
    if (isCalculusActiveLearning || isCalculusLecture) {
      setActivityTitle(title)
      return
    }
    localStorage.setItem('lastClickedActivity', JSON.stringify(currentActivity))
    if (type !== SECTION) {
      window.location.href = `${courseUrl}/#${hashLink}`
    } else {
      window.location.href = `${courseUrl}/#${sectionUUID}/${exerciseId}/`
    }
    toggleCheckList(false)
  }
  const onInstructorActivityClick = (
    isCalculusActiveLearning,
    themeName,
    instructorUUID,
    sectionUUID,
    exerciseId,
    lecturevideosUUID
  ) => {
    window.location.href = `
      ${`${courseUrl}/#${sectionUUID}/${isCalculusActiveLearning
        ? (lecturevideosUUID || exerciseId) : exerciseId}/` +
      `?instructor=${instructorUUID}${isCalculusActiveLearning
        ? `&theme_name=${themeName}` : ''}`}`
    toggleCheckList(false)
  }
  const dateAndSectionRange = () => (
    <SectionHeader
      data-testid='sectionListHeader'
      completed={sectionCompleted && toggleState}
      warningColor={warningColor}
    >
      <h5>
        {startDate} {endDate && ' - '}
        {endDate}
      </h5>
      <span />
      <p>{title}</p>
      {warningColor &&
        <ChecklistIcon
          width='16px'
          height='16px'
          color={warningColor}
          src='images/icons/icon-alert-exclamation.svg'
        />}
    </SectionHeader>
  )
  return (
    <>
      <ActivityListWrap data-testid='listWrap'>
        {showGuessworkCard ? renderCard() : null}
        {hasCohortStarted ? dateAndSectionRange() : null}
        <ActivityList>
          {sectionLists?.map(
            (
              {
                lockText,
                section_uuid: sectionUUID,
                endDate,
                assessmentColor,
                isActiveAssessment,
                title,
                description,
                hashLink,
                exerciseId,
                type,
                isCompleted,
                lectureVideos,
                activeLearningQuestions
              },
              index
            ) => {
              const currentActivity = {
                exerciseId: exerciseId || '',
                section_uuid: sectionUUID,
                title,
                description,
                type,
                isCompleted
              }
              const {
                isSameActivityTitleUUID,
                isSameDescription,
                isAnimated
              } = removeAlreadyAnimatedActivity(
                currentActivity,
                lastClickedActivity,
                toggleState,
                textAnimation
              )
              const isCalculusCourse = checkCourseNames(
                ['calculus', 'calculus.plus'],
                courseId
              )
              const isCalculusActiveLearning =
                isCalculusCourse &&
                title?.includes(SectionType.ACTIVE_LEARNING)
              const isCalculusLecture =
                isCalculusCourse && title?.includes(SectionType.LECTURE)
              const instructors = getLectureInstructors(lectureVideos, isCalculusLecture)
              const activeLearningInstructors =
                getActiveLearningInstructors(
                  activeLearningQuestions,
                  isCalculusActiveLearning,
                  studentData[STUDENT_ANSWERS]
                )
              const isExam = [EXAM, ESSAY].includes(type)
              const isQuiz = title?.includes(QUIZ)

              const options = isCalculusActiveLearning
                ? activeLearningInstructors
                : instructors
              const isExerciseActive = isExam ? isActiveAssessment
                : lockText
                  ? title?.includes(SectionType.GUESSWORK) && !lockText
                  : !lockText

              const shouldHideActivity = isAudit && isQuiz

              if (shouldHideActivity) return null

              const isTermBreak = type === BREAK

              return (
                <ActivityListItem
                  active={isExerciseActive}
                  isTermBreak={isTermBreak}
                  completed={isAnimated && toggleState}
                  assessmentColor={assessmentColor}
                  warningColor={warningColor}
                  textAnimation={
                    textAnimation &&
                    !isSameDescription && isSameActivityTitleUUID
                  }
                  key={index}
                  onClick={() => onActivityClick(
                    lockText,
                    title,
                    type,
                    sectionUUID,
                    exerciseId,
                    hashLink,
                    currentActivity,
                    isCalculusActiveLearning,
                    isCalculusLecture
                  )}
                >
                  <div className='checkMark draw' />
                  <ActivityDropdown
                    option={options}
                    show={activityTitle === title}
                    toggle={(e) => {
                      e.stopPropagation()
                      setActivityTitle('')
                    }}
                    isActiveLearning={isCalculusActiveLearning}
                    onClick={(
                      instructorUUID,
                      themeName,
                      lecturevideosUUID
                    ) =>
                      onInstructorActivityClick(
                        isCalculusActiveLearning,
                        themeName,
                        instructorUUID,
                        sectionUUID,
                        exerciseId,
                        lecturevideosUUID)}
                  />
                  {!isTermBreak && <h5>{title}</h5>}
                  <p>
                    {isSameActivityTitleUUID
                      ? lastClickedActivity?.description
                      : description}
                  </p>
                  {isSameActivityTitleUUID ? <h4>{description}</h4> : null}
                  {!isTermBreak && assessmentEndDate(endDate, isActiveAssessment, assessmentColor)}
                </ActivityListItem>
              )
            }
          )}
        </ActivityList>
      </ActivityListWrap>
      {sectionList?.length > 8 ? (
        <MoreButton
          data-testid='moreButton'
          onClick={() => setIsShowAll(!isShowAll)}
        >
          <img
            src={
              isShowAll
                ? '/images/icons/arrow-up.svg'
                : '/images/icons/arrow-down.svg'
            }
            alt='arrow-down'
          />
          <p>{isShowAll ? 'Show fewer' : 'Show all'}</p>
        </MoreButton>
      ) : null}
    </>
  )
}

const getCourseActivitiesLocalStorageKey = ({ courseId, activityType, email }) => {
  return `courseActivities_${email}_${courseId}_${activityType}`
}

const CourseActivities = ({
  course,
  toggleCheckList,
  hasCohortStarted,
  isFromCourseDetail,
  isGGUCohort
}) => {
  const context = useContext(AppContext)
  const user = useUserStore(state => state.user)
  const { email } = user || {}
  const [isLoading, setLoading] = useState(true)
  const [allSectionsData, setAllSectionsData] = useState([])

  useEffect(() => {
    const getSectionData = async () => {
      setLoading(true)
      const latestCohort = getLatestCohort(course)
      const sectionData = await api.getChecklistSectionsData(
        course?.id, latestCohort, course?.name
      )
      const formattedSections = getFormattedSections(sectionData)
      setAllSectionsData(formattedSections)
      setLoading(false)
    }
    getSectionData()
    // eslint-disable-next-line
  }, [])

  const getActivitiesLocalStorageKey = (activityType) => {
    return getCourseActivitiesLocalStorageKey({
      courseId: course.id,
      activityType,
      email
    })
  }

  // localStorage keys
  const moreThanTwoWeeksLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('moreThanTwoWeeks')
    : null

  const moreThanOneWeekLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('moreThanOneWeek')
    : null

  const allPastActivitiesClearedLocalStorageKey = course.id
    ? getActivitiesLocalStorageKey('allPastActivitiesCleared')
    : null

  useEffect(() => () => {
    if (!isFromCourseDetail) return

    const allPastActivitiesCleared = localStorage.getItem(
      allPastActivitiesClearedLocalStorageKey
    )

    if (allPastActivitiesCleared) {
      localStorage.removeItem(
        moreThanTwoWeeksLocalStorageKey
      )
      localStorage.removeItem(
        moreThanOneWeekLocalStorageKey
      )
      localStorage.removeItem(
        allPastActivitiesClearedLocalStorageKey
      )
    }
    // eslint-disable-next-line
  }, [])

  const latestCohort = getLatestCohort(course)
  const isAudit = isUserAuditor(latestCohort)
  const chapters = course?.chapters
  const isIntensive = course?.cohort?.duration <= INTENSIVE_COHORT_DURATION
  const { lastTwoWeeksRP, lastWeekRP } = getRecommendedProgress(
    context?.isCohortEnded,
    course?.courseResourcesSchedule
  )
  let formattedSchedules = getFormattedSchedule(
    course?.courseResourcesSchedule,
    isIntensive
  )
  const previousLastWeekSection = isIntensive
    ? getPreviousLastWeekSection(course?.courseResourcesSchedule, isIntensive)
    : lastWeekRP

  const currentWeekSection = getRecommendedProgressWeek(
    course?.courseResourcesSchedule,
    hasCohortStarted,
    isIntensive,
    previousLastWeekSection
  )
  let previousWeekSection = previousLastWeekSection ? [previousLastWeekSection] : []
  let currentWeekSections = currentWeekSection
  if (isIntensive) {
    formattedSchedules = getIntensiveSchedules(formattedSchedules)
    previousWeekSection = getIntensiveSchedules(previousWeekSection)
    currentWeekSections = getIntensiveSchedules(currentWeekSections)
  }
  let previousSection =
      previousSections(lastTwoWeeksRP, formattedSchedules, isIntensive)
  let allSections = []
  const { isCaughtUp, isWorkingAhead, workingAheadWeekToDisplay } = course
  if (isWorkingAhead) {
    const formattedSchedules = getFormattedSchedule(
      course?.courseResourcesSchedule,
      isIntensive
    )
    allSections = [formattedSchedules[workingAheadWeekToDisplay]]
  } else if (isCaughtUp) {
    const nextWeekSection =
      getNextWeekSection(course?.courseResourcesSchedule, isIntensive)
    allSections = Array.isArray(nextWeekSection) ? nextWeekSection : [nextWeekSection]
  } else {
    previousSection = isAudit ? previousSection
      : previousSection.map(section => (
        section?.assignmentType === BREAK ? section : { ...section, warningColor: RED }
      ))
    previousWeekSection = isAudit ? previousWeekSection
      : previousWeekSection.map(section => (
        section?.assignmentType === BREAK ? section : { ...section, warningColor: YELLOW }
      ))
    allSections = [
      ...previousSection,
      ...previousWeekSection,
      ...currentWeekSections
    ]
  }

  const filteredSchedules = getSectionTypeSchedules(allSections)
  const studentData = context?.coursesProgress?.find(
    progress => progress?.courseId === course?.id
  )?.studentProgress

  const { id: courseId } = course || {}

  const getHasPastTwoWeeksActivities = () => {
    if (isLoading || !allSectionsData?.length) return false

    // All past two weeks activities
    const previousTwoWeeksActivities = filteredSchedules.find(schedule => {
      const { warningColor, assignmentType } = schedule || {}
      return warningColor === RED && assignmentType !== BREAK
    })
    if (!previousTwoWeeksActivities) return false

    const sectionList = createSectionList({
      sectionsData: allSectionsData,
      dynamicCohortSchedule: previousTwoWeeksActivities,
      chapters,
      filteredSchedules,
      isIntensive,
      studentData,
      courseId
    })
    if (!sectionList?.length) return false

    const lastClickedActivity = JSON.parse(
      localStorage.getItem('lastClickedActivity')
    )
    const activeSectionList = sectionList.filter(
      section => filterClickedSection(section, lastClickedActivity)
    )

    if (!activeSectionList?.length) return false

    return true
  }
  const hasPastTwoWeeksActivities = getHasPastTwoWeeksActivities()

  const getHasPastOneWeekActivities = () => {
    if (isLoading || !allSectionsData?.length) return false

    // All past two weeks activities
    const previousWeekActivities = filteredSchedules.find(schedule => {
      const { warningColor, assignmentType } = schedule || {}
      return warningColor === YELLOW && assignmentType !== BREAK
    })
    if (!previousWeekActivities) return false

    const sectionList = createSectionList({
      sectionsData: allSectionsData,
      dynamicCohortSchedule: previousWeekActivities,
      chapters,
      filteredSchedules,
      isIntensive,
      studentData,
      courseId
    })
    if (!sectionList?.length) return false

    const lastClickedActivity = JSON.parse(
      localStorage.getItem('lastClickedActivity')
    )
    const activeSectionList = sectionList.filter(
      section => filterClickedSection(section, lastClickedActivity)
    )

    if (!activeSectionList?.length) return false

    return true
  }
  const hasPastOneWeekActivities = getHasPastOneWeekActivities()

  useMemo(() => {
    if (hasPastTwoWeeksActivities) {
      localStorage.setItem(moreThanTwoWeeksLocalStorageKey, true)
    }
  }, [moreThanTwoWeeksLocalStorageKey, hasPastTwoWeeksActivities])

  useMemo(() => {
    if (hasPastOneWeekActivities) {
      localStorage.setItem(moreThanOneWeekLocalStorageKey, true)
    }
  }, [moreThanOneWeekLocalStorageKey, hasPastOneWeekActivities])

  // This state is when the user has cleared past two weeks activities but not one week
  const getIsTwoWeeksActivitiesClearedButNotOneWeek = () => {
    if (isLoading) return false

    if (!moreThanTwoWeeksLocalStorageKey) return

    if (hasPastTwoWeeksActivities) return false

    const hadMoreThanTwoWeeksActivities = localStorage.getItem(
      moreThanTwoWeeksLocalStorageKey
    )
    if (!hadMoreThanTwoWeeksActivities) return false

    return hasPastOneWeekActivities
  }
  const twoWeeksActivitiesClearedButNotOneWeek = getIsTwoWeeksActivitiesClearedButNotOneWeek()

  // This state is when the user has cleared all past activities
  const getAreAllPastActivitiesCleared = () => {
    if (isLoading || !allSectionsData?.length) return false

    if (!moreThanOneWeekLocalStorageKey) return

    if (hasPastOneWeekActivities || hasPastTwoWeeksActivities) return false

    const hadMoreThanTwoWeeksActivities = localStorage.getItem(
      moreThanTwoWeeksLocalStorageKey
    )
    const hadMoreThanOneWeekActivities = localStorage.getItem(
      moreThanOneWeekLocalStorageKey
    )

    if (!hadMoreThanTwoWeeksActivities && !hadMoreThanOneWeekActivities) return false

    localStorage.setItem(
      allPastActivitiesClearedLocalStorageKey,
      true
    )

    return true
  }
  const allPastActivitiesCleared = getAreAllPastActivitiesCleared()

  const examsArray = chapters?.filter(chapter => chapter.type === EXAM)
  const { chapter_uuid: finalExamUUID } =
    examsArray?.[examsArray.length - 1] || {}
  const isFinalExamCompleted = studentData[EXAM_COMPLETE][finalExamUUID]

  const showActivities = useMemo(() => {
    if (isLoading) return false

    if (isGGUCohort) return filteredSchedules?.length && hasCohortStarted

    return !!filteredSchedules?.length
  }, [filteredSchedules, hasCohortStarted, isGGUCohort, isLoading])

  return (
    <CourseDetailWrap>
      <ActivitiesDetailHeader
        course={course}
        previousSection={previousSection}
        hasPastOneWeekActivities={hasPastOneWeekActivities}
        hasPastTwoWeeksActivities={hasPastTwoWeeksActivities}
        twoWeeksActivitiesClearedButNotOneWeek={twoWeeksActivitiesClearedButNotOneWeek}
        allPastActivitiesCleared={allPastActivitiesCleared}
        hasCohortStarted={hasCohortStarted}
        scheduleSection1={filteredSchedules[0]}
        studentData={studentData}
        isIntensive={isIntensive}
        isFinalExamCompleted={isFinalExamCompleted}
        isGGUCohort={isGGUCohort}
      />
      {showActivities ? (<Divider />) : null}
      {isLoading ? (
        <LoadingSpinner />
      ) : showActivities ? (
        filteredSchedules?.map((dynamicCohortSchedule, index) => (
          <RenderActivities
            dynamicCohortSchedule={dynamicCohortSchedule}
            chapters={chapters}
            toggleCheckList={toggleCheckList}
            allSectionsData={
              allSectionsData.length ? allSectionsData : course.allSectionsData
            }
            filteredSchedules={course?.courseResourcesSchedule}
            key={index}
            courseId={course?.id}
            examRetakes={context?.examRetakes}
            courseUnits={course?.courseUnits}
            isVIP={getIsVIP(course)}
            isVIPGradedContent={course?.vipGradedContent}
            isStudioCohort={isStudioCohort(course)}
            latestCohort={latestCohort}
            isIntensive={isIntensive}
            studentData={studentData}
            hasCohortStarted={hasCohortStarted}
          />
        ))
      ) : (
        <StyledP>
          The course opens on {course?.courseResourcesSchedule?.[0]?.startDate || ''}.
          Check back then to get started on your coursework!
        </StyledP>
      )}
    </CourseDetailWrap>
  )
}

CourseActivities.displayName = 'CourseDetail'
CourseActivities.propTypes = {
  course: PropTypes.object
}

export default CourseActivities
