import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import config from '../../config'
import Dashboard from '../Dashboard/Dashboard'
import MyCourses from '../MyCourses/MyCourses'
import Contact from '../Contact/Contact'
import {
  isOutlierAccount, onboardingFormSubmitted
} from '../../utilities/user'
import UpdatedExitFromPage from '../UpdatedExitFormPage/UpdatedExitFromPage'
import RejectionPage from '../ExitFormPage/RejectionPage'
import TermsOfUse from '../TermsOfUse/TermsOfUse'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import AccountArea from '../AccountArea'
import GuardianPermissionForm from '../GuardianPermission/GuardianPermissionForm'
import SetTokensCount from '../AddTokens/SetTokensCount'
import CourseCatalog from '../CourseCatalog/CourseCatalog'
import CatalogDetailPage from '../CatalogDetailPage/CatalogDetailPage'
import GGUGettingStarted from '../GGUGettingStarted/GGUGettingStarted'
import AchievementsTracker from '../AchievementsTracker'
import ScheduleReview from '../ScheduleReview/ScheduleReview'
import Confirmation from '../ScheduleReview/Confirmation'
import Pretest from '../Pretest'
import CoursePage from '../AccountArea/CoursePage/CoursePage'
import AmazonRefundRequestPage from '../AccountArea/AmazonRefundRequest'
import Alternatives from '../Alternatives/Alternatives'
import RelationshipAssignmentPage from '../RelationshipAssignmentPage/RelationshipAssignmentPage'
import AnnouncementsPage from '../Announcements/AnnouncementsPage'
import TransferRequest from '../UpdatedExitFormPage/TransferRequest'
import { AMAZON_REFUND_PATH } from '../../Constants/accountArea'
import { hasCourseProgress } from '../../utilities/course'
import { EMAIL_VERIFIED } from '../../Constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { getIsPurchasedCertificate } from '../../utilities/certificate'
import OnboardingChapter from '../OnboardingChapter'
import AudienceConsentForm from '../AudienceConsentForm/AudienceConsentForm'
import AudienceConsentStudentForm from '../AudienceConsentStudentForm/AudienceConsentStudentForm'
import RedirectComponent from '../RedirectComponent/RedirectComponent'
import PageNotFound from '../PageNotFound/PageNotFound'
import { useAuthStore, useUserStore } from '@outlier-org/lst-auth-react'
import { isPublicRoute } from '../../utilities/authUtils'
import Login from '../Login/Login'

const ContentRouter = () => {
  const [isAuthenticated, login] = useAuthStore((state) => [state.isAuthenticated, state.login])
  const {
    studentData, coursesProgress, certificates, isGGUStudent, courses
  } = useContext(AppContext) || {}

  const user = useUserStore((state) => state.user)

  const { email, email_verified: auth0EmailVerified } = user

  const { origin, href, search, hash } = window.location
  const pathname = hash.split('#')[1]

  const searchParams = new URLSearchParams(search)
  const emailParam = decodeURIComponent(searchParams.get('email') || '')

  const exitPagePath = '/account/manage-courses/:courseName/course-exit'
  const isAdmin = isOutlierAccount(email)

  const {
    [EMAIL_VERIFIED]: studentDataEmailVerified, instrideId
  } = studentData || {}
  const hasOnlyCollegeSuccessCourse = courses?.length === 1 &&
    config.isCollegeSuccessCourse(courses[0].id)
  const studentEmailVerified = auth0EmailVerified || studentDataEmailVerified ||
    hasOnlyCollegeSuccessCourse
  const hasProgressInAnyCourse = hasCourseProgress(coursesProgress)
  const hasAccessToDashboard = isAdmin || hasProgressInAnyCourse ||
    isGGUStudent || instrideId ||
    (studentEmailVerified && onboardingFormSubmitted(studentData))
  const shouldShowAchievements =
    config.hasAchievementsFlag && getIsPurchasedCertificate(certificates)

  const publicRoutes = () => {
    return [
      <Route
        key='terms-of-use'
        exact
        path='/terms-of-use'
        component={TermsOfUse}
      />,
      <Route
        key='privacy-policy'
        exact
        path='/privacy-policy'
        component={PrivacyPolicy}
      />,
      <Route
        key='guardian-permission'
        exact
        path='/guardian-permission'
        component={GuardianPermissionForm}
      />,
      <Route
        key='audience-consent'
        exact
        path='/audience-consent'
        component={AudienceConsentForm}
      />,
      <Route
        key='course-download-redirect'
        exact
        path='/course-download-redirect/:slug'
        component={RedirectComponent}
      />,
      <Route
        key='login'
        exact
        path='/login'
        component={Login}
      />
    ]
  }

  if (isPublicRoute(pathname) && !isAuthenticated) {
    return (
      <Switch>
        {publicRoutes()}
        <Route component={() =>
          login({
            redirect_uri: origin,
            login_hint: emailParam,
            appState: { targetUrl: href }
          })}
        />
      </Switch>)
  }

  if (!coursesProgress || !studentData) return <LoadingSpinner />

  return !hasAccessToDashboard
    ? (
      <Switch>
        {publicRoutes()}
        <Route path='/*' component={Dashboard} />
      </Switch>
    ) : (
      <Switch>
        {publicRoutes()}
        <Route exact path='/' component={Dashboard} />
        <Route exact path='/account' component={AccountArea} />
        <Route exact path='/account/tokens' component={PageNotFound} />
        <Route exact path='/account/:tabName' component={AccountArea} />
        {config.hasAnnouncementsFlag() &&
          <Route exact path='/announcements' component={AnnouncementsPage} />}
        <Route exact path='/onboarding-chapter' component={OnboardingChapter} />
        <Route exact path='/refundToken' component={AccountArea} />
        <Route exact path='/add-tokens' component={SetTokensCount} />
        <Route exact path='/account/manage-courses/:courseName' component={CoursePage} />
        <Route exact path={AMAZON_REFUND_PATH} component={AmazonRefundRequestPage} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/my-courses' component={MyCourses} />
        <Route exact path='/catalog' component={CourseCatalog} />
        <Route exact path='/catalog/:courseId' component={CatalogDetailPage} />
        <Route exact path='/catalog/:courseId/pretest' component={Pretest} />
        {isGGUStudent && (
          <Route exact path='/ggu-getting-started/:partNumber?' component={GGUGettingStarted} />
        )}
        {shouldShowAchievements && (
          <Route exact path='/achievements' component={AchievementsTracker} />
        )}
        <Route exact path='/review-schedule' component={ScheduleReview} />
        <Route exact path='/review-schedule/relationship-assignment' component={RelationshipAssignmentPage} />
        <Route exact path='/confirmation' component={Confirmation} />
        <Route exact path='/catalog/:courseId/alternatives' component={Alternatives} />
        <Route exact path={`${exitPagePath}/transfer`} component={TransferRequest} />
        <Route exact path={`${exitPagePath}/withdraw`} component={UpdatedExitFromPage} />
        <Route exact path={`${exitPagePath}/drop`} component={UpdatedExitFromPage} />
        <Route exact path={`${exitPagePath}/audit`} component={UpdatedExitFromPage} />
        <Route exact path={`${exitPagePath}/exception`} component={UpdatedExitFromPage} />
        <Route exact path={`${exitPagePath}/form-rejected`} component={RejectionPage} />
        <Route exact path={`${exitPagePath}/:formType/form`} component={UpdatedExitFromPage} />

        <Route exact path='/audience-consent-student' component={AudienceConsentStudentForm} />
        <Route exact path='/course-exit/form-rejected' component={RejectionPage} />
        <Route path='/*' component={Dashboard} />
      </Switch>
    )
}

export default ContentRouter
