import React, { useState, useEffect, useContext, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import RelationshipModal from './Modal'
import config from '../../config'
import api from '../../api'
import { isConsultant } from '../../utilities/user'
import { useUserStore } from '@outlier-org/lst-auth-react'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { FAILURE } from '../ErrorMessage/errorType'
import {
  RelationshipPageWrapper,
  OverrideCSSProperties,
  BreadCrumbComponent,
  StyledLabel,
  OptionsWrapper,
  ConfirmButton,
  ButtonLink,
  UseThisToken
} from './style'
import { addNewCourse } from '../../utilities/course'
import { getFilteredTokens } from '../../utilities/tokenUtils'

export default function RelationshipAssignmentPage () {
  const {
    state,
    state: {
      relationshipTokens = [],
      availableTokens = [],
      sortedCourses = [],
      isDirectedFromScheduleReview
    } = {}
  } = useLocation()

  const assignTokensCalled = useRef(false)
  const [notEnoughTokens, setNotEnoughTokens] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedCourseId, setSelectedCourseId] = useState(sortedCourses[0]?.id)
  const [keptTokens, setKeptTokens] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [newSortedCourses, setNewSortedCourses] = useState([
    ...sortedCourses,
    { id: '0', name: 'Save this token for later' }
  ])
  const [availableRlnTokens, setAvailableRlnTokens] = useState(
    relationshipTokens
  )
  const user = useUserStore(state => state.user)
  const {
    setStudentCourses,
    setStudentAttempts,
    setTokens
  } = useContext(AppContext)
  const history = useHistory()

  const {
    id,
    originalCertificate: {
      name: certificateName
    } = {},
    relationship: [
      {
        fields: {
          relationshipName,
          relationshipType: [relationshipType] = []
        } = {}
      } = {}
    ] = []
  } = availableRlnTokens[0] || {}

  useEffect(() => {
    if (!state ||
      !relationshipTokens?.length ||
       !sortedCourses?.length ||
        !isDirectedFromScheduleReview) {
      return history.push('/review-schedule?assignTokens=true')
    }
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isDirectedFromScheduleReview) return
    const tokensNotEnough =
      availableTokens.length - keptTokens.length < sortedCourses.length
    if (tokensNotEnough) return setNotEnoughTokens(true)

    setAvailableRlnTokens((prevValue) => {
      return prevValue.filter((token) => {
        return (
          !newSortedCourses.find((course) => {
            return course.tokenId === token.id
          }) &&
          !keptTokens.find((tokenId) => {
            return tokenId === token.id
          })
        )
      })
    })

    const { id } = newSortedCourses.find((course) => {
      return !course.tokenId
    })

    setSelectedCourseId(id || '0')
    // eslint-disable-next-line
  }, [newSortedCourses, keptTokens])

  useEffect(() => {
    if (!isDirectedFromScheduleReview) return
    const allCoursesPaired =
      newSortedCourses.filter((course) => {
        return !course.tokenId
      }).length === 1
    const allTokensPaired = !availableRlnTokens.length

    if (allCoursesPaired || allTokensPaired) {
      !assignTokensCalled.current && onFinish()
    }
    // eslint-disable-next-line
  }, [newSortedCourses, availableRlnTokens])

  function onConfirm () {
    assignTokensCalled.current = false
    if (notEnoughTokens) {
      localStorage.setItem('userCart',
        JSON.stringify(newSortedCourses.filter((course) => course.id !== '0')))
      return history.push({
        pathname: '/add-tokens',
        state: {
          backToPageName: 'Relationship Assignment',
          redirectToPath: '/review-schedule/relationship-assignment',
          keptTokens: keptTokens.length
        }
      })
    }
    if (selectedCourseId === '0') {
      return setKeptTokens((prevValue) => {
        return [...prevValue, id]
      })
    }
    setNewSortedCourses((prevValue) => {
      return prevValue.map((course) => {
        if (course.id !== selectedCourseId) return course
        return {
          ...course,
          tokenId: id
        }
      })
    })
  }

  function onUseThisToken () {
    setKeptTokens((prevValue) => {
      return prevValue.filter((tokenId) => tokenId !== id)
    })
    setNotEnoughTokens(false)
  }

  async function onFinish () {
    assignTokensCalled.current = true
    const courses = newSortedCourses.filter((course) => course.id !== '0')
    const body = courses.map((course) => {
      if (!course.tokenId) {
        return {
          cohortId: course?.cohortId,
          courseId: course?.id
        }
      }
      return {
        cohortId: course?.cohortId,
        courseId: course?.id,
        tokenId: course?.tokenId
      }
    })

    setIsLoading(true)

    try {
      const data = await api.assignTokens(body)
      localStorage.removeItem('userCart')
      if (data) {
        addNewCourse(body)
        const [
          studentCourses,
          studentTokens,
          studentAttempts
        ] = await Promise.all([
          api.getStudentCourses(isConsultant(user?.email)),
          api.getAllTokens({ activityLog: config.showTokenActivityLog() }),
          api.getStudentAttempts()
        ])

        setStudentCourses(studentCourses)
        setTokens(getFilteredTokens(studentTokens))
        setStudentAttempts(studentAttempts)

        history.push('/confirmation')
      }
      return setIsError(true)
    } catch (e) {
      console.log({ e })
      setIsError(true)
    }

    setIsLoading(false)
  }

  const content = [
    {
      title:
        relationshipType === 'scholarship'
          ? 'Let’s get your scholarship set up!'
          : 'Lets get you set up!',
      description: `Choose which course will use your ${relationshipName || certificateName} token.`
    },
    {
      title: 'Add 1 token to continue',
      description: `Because you chose not to use  ${
        relationshipType === 'scholarship'
          ? 'your scholarship'
          : 'this ' + (relationshipName || certificateName)
      } token, you’ll need to add at least 1 more token to your account to complete the process.`
    }
  ]

  const { title, description } = content[!notEnoughTokens ? 0 : 1]
  if (isLoading) return <LoadingSpinner />
  if (isError) return <ErrorMessage errorType={FAILURE} />

  return (
    <>
      <OverrideCSSProperties displayModal={showModal} />
      {showModal && (
        <RelationshipModal
          relationshipType={relationshipType}
          showModal={() => setShowModal(false)}
        />
      )}
      <BreadCrumbComponent
        path={notEnoughTokens ? '/add-tokens' : '/review-schedule'}
        title={notEnoughTokens ? 'Token setup' : 'Schedule'}
        state={notEnoughTokens
          ? {
            backToPageName: 'Schedule Review',
            redirectToPath: '/review-schedule/relationship-assignment'
          }
          : {}}
      />
      <RelationshipPageWrapper>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          {!notEnoughTokens && (
            <ButtonLink onClick={() => setShowModal(true)}>
              Why do I need to decide?
            </ButtonLink>
          )}
        </div>
        {!notEnoughTokens && (
          <OptionsWrapper>
            {newSortedCourses
              .filter((course) => {
                return !course.tokenId
              })
              .map((course, index) => {
                return (
                  <StyledLabel htmlFor={course.id} key={index}>
                    {course.name}
                    <input
                      type='radio'
                      name='course'
                      checked={course.id === selectedCourseId || index === 0}
                      id={course.id}
                      onChange={(e) => {
                        setSelectedCourseId(e.target.id)
                      }}
                    />
                    <span />
                  </StyledLabel>
                )
              })}
          </OptionsWrapper>
        )}
        <ConfirmButton onClick={onConfirm}>
          {!notEnoughTokens ? 'confirm' : 'add tokens'}
        </ConfirmButton>
        {notEnoughTokens && (
          <UseThisToken onClick={onUseThisToken}>
            Actually, use this token now.
          </UseThisToken>
        )}
      </RelationshipPageWrapper>
    </>
  )
}
