import {
  AUDIT_TRANSCRIPT,
  AUDIT
} from '../Constants/studentStatus'
import config from '../config'
import { getLatestCohort } from './course'
import { dateToSecondsSinceEpoch } from './dateTime'
import { isVIPCohort } from './cohort'
import { useUserStore } from '@outlier-org/lst-auth-react'

export const isOutlierAccount = (email) => {
  if (!email) return false

  return email.endsWith('@outlier.org') ||
    email.endsWith('@engineering.outlier.org') || isConsultant(email)
}

export const isConsultant = (email = '') => {
  return email?.endsWith('@consultant.outlier.org')
}

export const isUserAuditor = latestCohort => {
  if (!latestCohort) return false
  const { studentStatus } = latestCohort
  return [AUDIT, AUDIT_TRANSCRIPT].includes(studentStatus)
}

export const canOverrideStudentEmail = async () => {
  const user = useUserStore.getState().user
  const { email } = user || {}
  if (!email) return false

  const isAdmin = isOutlierAccount(email)
  return isAdmin && !!config.studentEmailOverride
}

export const onboardingFormSubmitted = studentData => {
  if (!studentData) return false

  const {
    firstName,
    lastName,
    dateOfBirth,
    enrollingStudentCertification,
    email
  } = studentData

  return !!(firstName && lastName && email && dateOfBirth && enrollingStudentCertification)
}

export const getStudentAge = dateOfBirth => {
  if (!dateOfBirth) return

  const birthday = +new Date(dateOfBirth)
  if (isNaN(birthday)) return

  return ~~((Date.now() - birthday) / (31557600000))
}

export const needsGuardianPermission = ({ dateOfBirth, under13 }) => {
  const studentAge = getStudentAge(dateOfBirth)

  /* Students whose age is below 13, but have their under13 column
  unchecked in Airtable, are allowed to access site after getting
  guardian permission */
  return studentAge < 13 ? !under13 : studentAge <= 17
}

export const canOverrideTOS = courses => {
  if (!courses?.length) return false

  return courses.every(course => {
    const latestCohort = getLatestCohort(course)
    if (!latestCohort) return false

    const {
      relationship: { fields: { relationshipType = [], hideTOS } = {} } = {}
    } = latestCohort
    return relationshipType.includes('partnership') && hideTOS
  })
}

export const showTermsAgreement = ({
  courses,
  dateTOS,
  guardianTOSDate,
  under13,
  dateOfBirth
}) => {
  if (canOverrideTOS(courses)) return {}

  const needsPermission = needsGuardianPermission({ dateOfBirth, under13 })

  if (needsPermission) {
    return guardianTOSDate ? {} : { showGuardianPermission: true }
  }

  return dateTOS ? {} : { showTermsModal: true }
}

export const isLatestTermsAccepted = dateTOS => {
  if (!dateTOS) return false

  const termsUpdatedDate = getTermsUpdatedDate()
  const dateTOSInSeconds = dateToSecondsSinceEpoch(new Date(dateTOS))
  return dateTOSInSeconds > termsUpdatedDate
}

export const getTermsUpdatedDate = () => {
  return dateToSecondsSinceEpoch(new Date(config.termsUpdatedDate))
}

export const getIsVIP = (course, studentData) => {
  const isVIPUser = course?.vip || studentData?.vip
  if (isVIPUser) return isVIPUser

  const latestCohort = getLatestCohort(course)
  return !!isVIPCohort(latestCohort?.cohortName || latestCohort?.name)
}
