import React, { useState, useContext, useEffect, useMemo } from 'react'
import api from '../../../api'
import { AppContext } from '../../ContextProvider/ContextProvider'
import { STUDY_COMMITMENT, WEEKDAYS } from '../../../Constants/accountArea'
import { STUDY_BLOCKS_SCHEDULE_STARTED } from '../../../Constants/eventType'
import {
  getActiveCourses, getRecommendedHours,
  getActiveDays, enumerateStudyBlocksSessions, getStudyBlocksFromSessions,
  getInactiveStudyBlocksIDs,
  getAreRemindersEqual,
  calculateTotalHours,
  getSBSessionsObj
} from './utils'
import TimeFrames from './TimeFrames'
import ConfirmationModal from './ConfirmationModal'
import ToastNotification from './ToastNotification'
import ToolTip from '../../CatalogDetailPage/Tooltip'
import { CheckBox } from '../../OnboardingForm/style'
import CalendarList from '../../CalendarList/CalendarList'
import { getCoursesLatestCohort } from '../../../utilities/course'
import { Spinner } from 'react-bootstrap'
import { PrimaryButton, SecondaryButton, TransparentButton } from '../style'
import {
  DayElement, DaysContainer, PageHeader, SectionText, SectionTitle,
  AddToCalendarIcon, ButtonsContainer, CustomSelect, courseSelectStyles,
  ErrorMessage, PageContainer, StreaksWrapper, StreakContainer, StreakTitle,
  SparkIcon, SparkPlusIcon, StreakText
} from './style'
import { MULTIPLE } from '../../../Constants/frequency'
import utilities from '../../../utilities'
import {
  getLatestSession,
  getSessionsArray,
  getStreaks
} from '../../../utilities/studyBlocks'

function StudyBlocks (props) {
  const { courses = [], prospects = [{}] } = useContext(AppContext)

  const [reminders, setReminders] = useState({ email: false, sms: false })
  const [savedReminders, setSavedReminders] = useState({ email: false, sms: false })
  const [showCalendarList, setShowCalendarList] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showToastNotification, setShowToastNotification] = useState(false)

  const preferredTimeCommitment = prospects?.[0]?.studyMode

  const activeCourses = getActiveCourses(courses)
  const recommendedHours = getRecommendedHours(activeCourses)

  const [savedSBSessions, setSavedSBSessions] = useState(null)
  const [savedSBSchedule, setSavedSBSchedule] = useState(null)
  const [selectedDays, setSelectedDays] = useState([])
  const [selectedStudyBlocks, setSelectedStudyBlocks] = useState([])
  const [calendarKey, setCalendarKey] = useState('')

  const streaksInfo = useMemo(() => {
    const sessionsArray = getSessionsArray(savedSBSessions)
    const latestSession = getLatestSession(sessionsArray)
    const { currentStreak, longestStreak } = getStreaks(sessionsArray, latestSession)

    return { currentStreak, longestStreak, showStreaks: !!currentStreak }
  }, [savedSBSessions])

  const showTimeCommitmentMessage = preferredTimeCommitment &&
    preferredTimeCommitment !== STUDY_COMMITMENT.FREE_TIME && !savedSBSessions

  const isAddToCalendarDisabled = !Object.keys(savedSBSessions || {}).length

  const [loadingData, setLoadingData] = useState(
    { isLoading: false, error: false, type: '' })
  const showSaveError = loadingData.error && loadingData.type === 'save'
  const showCalendarError = loadingData.error && loadingData.type === 'calendar'

  useEffect(() => {
    const getSavedStudyBlocks = async () => {
      setLoadingData({ isLoading: true, error: false })
      const { studyBlocks, reminders = [] } = await api.getStudyBlocks() || {}
      setLoadingData({ isLoading: false, error: false })

      const savedReminders = Object.fromEntries(reminders?.map(type => [type, true]) || [])
      setReminders(savedReminders)
      setSavedReminders(savedReminders)

      setSavedSBSessions(studyBlocks)

      const studyBlocksSchedule = getStudyBlocksFromSessions(studyBlocks)
      setSavedSBSchedule(studyBlocksSchedule)

      setSelectedStudyBlocks(studyBlocksSchedule)
      setSelectedDays(studyBlocksSchedule?.map(
        ({ day, blocks }) => blocks?.length && { name: day }).filter(Boolean))
    }

    const submitSegmentEvents = () => {
      api.submitTrackedEvent({
        event: STUDY_BLOCKS_SCHEDULE_STARTED,
        frequency: MULTIPLE,
        properties: {
          time_stamp: Date.now()
        }
      })
    }

    submitSegmentEvents()
    getSavedStudyBlocks()
    // eslint-disable-next-line
  }, [])

  const [haveOverlappingBlocks, setHaveOverlappingBlocks] = useState(false)

  const checkOverlappingBlocks = () => {
    const isOverlapping = selectedStudyBlocks.some(({ blocks }) => {
      const blocksCopy = [...blocks]
      const sortedBlocks = blocksCopy.sort((a, b) => a.start - b.start)
      return sortedBlocks.some((block, index) => {
        const nextBlock = sortedBlocks[index + 1]
        if (!nextBlock) return false

        return block.end > nextBlock.start
      })
    })

    return isOverlapping
  }

  useEffect(() => {
    setHaveOverlappingBlocks(checkOverlappingBlocks())
  // eslint-disable-next-line
  }, [selectedStudyBlocks])

  const totalHours = calculateTotalHours(selectedStudyBlocks)
  const showHoursWarning = totalHours < recommendedHours && !!selectedDays.length

  const updateSelectedStudyBlocks = (newSelectedDays) => {
    const newStudyBlocks = selectedStudyBlocks.map(({ day, blocks }) => {
      const isDaySelected = newSelectedDays.find(selectedDay => selectedDay.name === day)
      if (!isDaySelected) return { day, blocks: [] }

      if (!blocks.length) {
        const newBlock = { start: 0, end: 0 }
        return { day, blocks: [newBlock] }
      }

      return { day, blocks }
    })

    setSelectedStudyBlocks(newStudyBlocks)
  }

  const onDayClick = dayName => {
    const newSelectedDays = selectedDays.map(e => e.name).includes(dayName)
      ? selectedDays.filter(e => e.name !== dayName)
      : [...selectedDays, { name: dayName }]

    setSelectedDays(newSelectedDays)
    updateSelectedStudyBlocks(newSelectedDays)
  }

  const onReminderClick = e => {
    const { name, checked } = e.target
    setReminders({ ...reminders, [name]: checked })
  }

  const handleCalendarDropdown = () => setShowCalendarList(!showCalendarList)
  const handleAddToCalendar = async () => {
    const { data, success } = await api.createStudyBlocksCalendar()
    if (!success) {
      return setLoadingData({ isLoading: false, error: true, type: 'calendar' })
    }

    setCalendarKey(data?.uniquekey)
    return data?.uniquekey
  }
  const onAddToCalendar = async () => {
    if (calendarKey) return handleCalendarDropdown()
    const newCalendarKey = await handleAddToCalendar()
    setShowCalendarList(!!newCalendarKey)
  }

  const areStudyBlocksEqual = JSON.stringify(savedSBSchedule) === JSON.stringify(selectedStudyBlocks)
  const areRemindersEqual = getAreRemindersEqual(savedReminders, reminders)

  const isSaveButtonDisabled =
    (haveOverlappingBlocks || areStudyBlocksEqual || loadingData.isLoading) && areRemindersEqual
  const isCancelButtonDisabled = (areStudyBlocksEqual || loadingData.isLoading) && areRemindersEqual

  const onSaveClick = async () => {
    if (showHoursWarning && !showConfirmationModal) return setShowConfirmationModal(true)

    const earliestCohortStartTime = getCoursesLatestCohort(activeCourses, true)?.dateStart
    const latestCohortEndTime = getCoursesLatestCohort(activeCourses)?.cohortEndTime

    const newStudyBlocksSessions = enumerateStudyBlocksSessions(
      selectedStudyBlocks, earliestCohortStartTime, latestCohortEndTime)
    const inactiveStudyBlocksIDs = getInactiveStudyBlocksIDs(
      savedSBSessions, newStudyBlocksSessions)
    const remindersKeys = Object.keys(reminders).filter(key => reminders[key])

    setLoadingData({ isLoading: true, error: false })
    const { success } = savedSBSessions ? await api.updateStudyBlocks(
      inactiveStudyBlocksIDs, newStudyBlocksSessions, remindersKeys
    ) : await api.saveStudyBlocks(newStudyBlocksSessions, remindersKeys)

    if (!success) {
      setShowConfirmationModal(false)
      return setLoadingData({ isLoading: false, error: true, type: 'save' })
    }
    setLoadingData({ isLoading: false, error: false })

    const SBSessionsObj = getSBSessionsObj(newStudyBlocksSessions)
    setSavedSBSessions(SBSessionsObj)
    setSavedSBSchedule(getStudyBlocksFromSessions(SBSessionsObj))

    setShowToastNotification(true)
    if (showHoursWarning && showConfirmationModal) setShowConfirmationModal(false)
  }

  const onCancelClick = () => {
    setLoadingData({ isLoading: false, error: false })
    const oldStudyBlocks = savedSBSchedule || null
    setSelectedStudyBlocks(oldStudyBlocks)
    setSelectedDays(getActiveDays(oldStudyBlocks))
  }

  return (
    <PageContainer>
      {streaksInfo?.showStreaks && (
        <>
          <PageHeader>
            Your streaks
          </PageHeader>

          <StreaksWrapper data-testid='streaks-info'>
            <StreakContainer>
              <StreakTitle>
                <SparkIcon style={{ marginRight: '12px' }} />
                Current streak
              </StreakTitle>

              <StreakText>{streaksInfo?.currentStreak} blocks</StreakText>
            </StreakContainer>

            <StreakContainer>
              <StreakTitle>
                <SparkPlusIcon style={{ marginRight: '12px' }} />
                Longest streak
              </StreakTitle>

              <StreakText>{streaksInfo?.longestStreak} blocks</StreakText>
            </StreakContainer>
          </StreaksWrapper>
        </>
      )}

      <PageHeader data-testid='study-blocks'>
        Study blocks
        <ToolTip
          dataTestId='tooltip'
          placement='right'
          tooltipText='The schedule will remind you of the days you’ve chosen to dedicate to coursework;
            it does not affect assessment deadlines and can be changed at any time.'
        >
          <img src='images/icons/tooltip-i.svg' alt='tooltip-icon' />
        </ToolTip>
      </PageHeader>
      <SectionText style={{ marginBottom: '32px' }}>
        Students who progress in coursework 3 - 5 times a week are 29x more likely to earn a passing grade.
      </SectionText>

      <SectionTitle
        marginBottom={showTimeCommitmentMessage ? '12px' : '24px'}
      >
        Which days would you like to commit to coursework?
      </SectionTitle>
      {showTimeCommitmentMessage && (
        <SectionText>
          You've indicated you're a {preferredTimeCommitment} student.
          We're starting you off with these days, but you can adjust accordingly!
        </SectionText>
      )}

      <DaysContainer>
        {WEEKDAYS.map(({ name }, index) => (
          <DayElement
            key={index}
            selected={selectedDays.map(e => e.name).includes(name)}
            onClick={() => onDayClick(name)}
          >
            {name}
          </DayElement>
        ))}
      </DaysContainer>

      <CustomSelect
        isMulti
        options={WEEKDAYS}
        value={selectedDays}
        placeholder='Select days'
        getOptionLabel={option => option.name}
        getOptionValue={option => option.name}
        isSearchable={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        classNamePrefix='select'
        styles={courseSelectStyles}
        onChange={event => {
          setSelectedDays(event)
          updateSelectedStudyBlocks(event)
        }}
      />

      <SectionTitle>
        What time frame?
      </SectionTitle>
      <SectionText>
        Hours suggested based on your current enrollment: {recommendedHours} hours per week
      </SectionText>

      {haveOverlappingBlocks && (
        <ErrorMessage>
          <img alt='alert-icon' src='images/icons/icon-alert-exclamation.svg' />
          One or more of your study blocks are overlapping
        </ErrorMessage>
      )}

      {showHoursWarning && (
        <ErrorMessage>
          <img alt='alert-icon' src='images/icons/icon-alert-exclamation.svg' />
          The hour(s) you’ve entered don’t meet the suggested hours per week!
        </ErrorMessage>
      )}

      <TimeFrames
        selectedStudyBlocks={selectedStudyBlocks}
        setSelectedStudyBlocks={setSelectedStudyBlocks}
      />

      <SectionText warning={showHoursWarning}>
        Total hours:{' '}
        <span>
          {utilities.roundNumber(totalHours)} hours
        </span>
      </SectionText>

      <SectionTitle marginBottom='16px'>
        What’s your preferred channel to receive reminders?
      </SectionTitle>

      <CheckBox
        marginBottom='21px' width='fit-content'
      >
        Email
        <input type='checkbox' onChange={e => onReminderClick(e)} name='email' checked={!!reminders.email} />
        <span />
      </CheckBox>
      <CheckBox
        marginBottom='26px' width='fit-content'
      >
        SMS
        <input type='checkbox' onChange={e => onReminderClick(e)} name='sms' checked={!!reminders.sms} />
        <span />
      </CheckBox>

      <TransparentButton
        onClick={onAddToCalendar}
        disabled={isAddToCalendarDisabled}
        style={{
          marginBottom: showCalendarError ? '12px' : '48px', position: 'relative'
        }}
      >
        <AddToCalendarIcon style={{ marginRight: '8px' }} />
        add to calendar
        {showCalendarList && (
          <CalendarList
            calendarKey={calendarKey}
            handleCalendarDropdown={handleCalendarDropdown}
          />
        )}
      </TransparentButton>

      {showCalendarError && (
        <ErrorMessage
          style={{ marginBottom: '48px' }}
        >
          <img alt='alert-icon' src='images/icons/icon-alert-exclamation.svg' />
          There was an error adding your study blocks to the calendar.
          Please try again.
        </ErrorMessage>
      )}

      <ButtonsContainer>
        <PrimaryButton
          data-testid='save-btn'
          disabled={isSaveButtonDisabled}
          onClick={onSaveClick}
        >
          {loadingData.isLoading && !showConfirmationModal
            ? <Spinner animation='border' /> : 'save'}
        </PrimaryButton>

        <SecondaryButton
          disabled={isCancelButtonDisabled}
          onClick={onCancelClick}
        >
          cancel
        </SecondaryButton>
      </ButtonsContainer>

      {showSaveError && (
        <ErrorMessage>
          <img alt='alert-icon' src='images/icons/icon-alert-exclamation.svg' />
          There was an error {savedSBSessions ? 'updating' : 'saving'} your study blocks,
          Please try again.
        </ErrorMessage>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          isLoading={loadingData.isLoading}
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          onSaveClick={onSaveClick}
        />
      )}

      {showToastNotification && (
        <ToastNotification
          show={showToastNotification}
          setShow={setShowToastNotification}
          calendarKey={calendarKey}
          handleAddToCalendar={handleAddToCalendar}
          isAddToCalendarDisabled={isAddToCalendarDisabled}
        />
      )}
    </PageContainer>
  )
}

StudyBlocks.displayName = 'StudyBlocks'
export default StudyBlocks
