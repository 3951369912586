import React, { useContext, useEffect, useState } from 'react'
import qs from 'qs'
import take from 'lodash/take'
import isEmpty from 'lodash/isEmpty'
import { Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PageTitle from '../PageTitle/PageTitle'
import PageContent from '../PageContent/PageContent'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import WelcomeMessage from '../WelcomeMessage/WelcomeMessage'
import Courses from '../MyCourses/Courses'
import ExploreMoreCourses from '../ExploreMoreCourses/ExploreMoreCourses'
import NotEnrolled from '../NotEnrolled/NotEnrolled'
import WhatsNext from '../WhatsNext/WhatsNext'
import { AppContext } from '../ContextProvider/ContextProvider'
import {
  isStudentEnrolled,
  getMoreCourses,
  hasCurrentCourseProgress,
  hasCourseProgress,
  getPaidCourses
} from '../../utilities/course'
import config from '../../config'
import {
  Link,
  WhatsNextWrapper,
  ExploreMoreCoursesWrapper,
  ExploreCatalog,
  MoreCoursesTitle,
  CourseTitle,
  CoursesTitleDiv,
  ViewAllDiv,
  CardsDiv
} from './style'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { FAILURE } from '../ErrorMessage/errorType'
import {
  isOutlierAccount,
  onboardingFormSubmitted,
  showTermsAgreement
} from '../../utilities/user'
import {
  EMAIL_VERIFIED,
  LESSON0_VIEWED,
  GGU_WELCOME_PAGE_VIEW,
  PERMISSION_REQUESTED_KEY,
  INSTRIDE_WELCOME_PAGE_VIEW,
  HIDE_DASHBOARD_ITEM
} from '../../Constants'
import VerifyEmailStudent from '../VerifyEmail/VerifyEmailStudent'
import OnboardingForm from '../OnboardingForm/OnboardingForm'
import GuardianPermission from '../GuardianPermission/GuardianPermission'
import {
  PERMISSION_AWAITED,
  PERMISSION_GRANTED,
  PERMISSION_REQUIRED
} from '../GuardianPermission/permissionStates'
import TermsAgreementModal from '../TermsAgreementModal/TermsAgreementModal'
import WelcomePage from '../WelcomeMessage/WelcomePage'
import Under13 from '../WelcomeMessage/Under13'
import VoucherUploadModal from '../VoucherUploadModal'
import WhatsNextUpdate from '../WhatsNextUpdate/WhatsNextUpdate'
import TuitionPaymentRequestModal from '../TuitionPaymentRequestModal/TuitionPaymentRequestModal'
import GGUWelcomePage from '../GGUWelcomePage/GGUWelcomePage'
import InstrideWelcomePage from '../WelcomeMessage/InstrideWelcomePage'
import EnrollmentConfirmation from '../GGUEnrollmentFlow/EnrollmentConfirmation'
import DegreesPlus from '../Degrees/DegreesPlus'
import api from '../../api'
import { ArrowIcon } from '../AccountArea/style'
import StudyBlocksDisplay from '../StudyBlocksDisplay'
import DashboardRedesign from '../DashboardRedesign/DashboardRedesign'
import { EXCLUDED_PARTNER_PROGRAMS } from '../../Constants/instride'
import RegistrationForm from '../RegistrationForm/RegistrationForm'
import { useUserStore } from '@outlier-org/lst-auth-react'

export const COURSE_DISPLAY_LIMIT = 6

const Dashboard = () => {
  const history = useHistory()
  const [showTOS, setShowTOS] = useState(false)
  const [showEC, setShowEC] = useState(shouldShowEC())
  const [component, setComponent] = useState(<></>)
  const [additionalCourses, setAdditionalCourses] = useState([])
  const user = useUserStore((state) => state.user) || {}
  const {
    courses,
    isGGUStudent,
    isActiveGGUStudent,
    activeCourses,
    showFailure,
    coursesProgress,
    isContextLoading,
    studentData,
    isGGUStudentEnrolled,
    hiddenCourses,
    updateContext,
    prospects,
    isGGUDegreeDataLoading,
    isStudioCohort,
    studentAttempts,
    areAllCoursesProfCert
  } = useContext(AppContext)
  const { email, email_verified: auth0EmailVerified } = user
  const {
    instrideId
  } = studentData || {}
  const whatsNextUpdate = config.whatsNextUpdate

  useEffect(() => {
    window.location.hash = '#/'

    // eslint-disable-next-line
  }, [window.location.hash])

  useEffect(() => {
    if (!config.hasLesson0Flag()) return
    if (isGGUDegreeDataLoading || !isActiveGGUStudent) return

    const gguCoursesProgress = coursesProgress?.filter(course => {
      return config.courseIdToName(course?.courseId)?.endsWith('.plus')
    })
    const hasGGUCourseProgress = hasCourseProgress(gguCoursesProgress)
    if (!studentData || studentData[LESSON0_VIEWED] || hasGGUCourseProgress) return

    const { lesson0Viewed } = qs.parse(window.location.search?.slice(1)) || {}
    if (lesson0Viewed === 'true') {
      updateContext({
        studentData: {
          ...studentData,
          [LESSON0_VIEWED]: true
        }
      })
      api.setStudentData(LESSON0_VIEWED, { [LESSON0_VIEWED]: true })
      return
    }

    history.push('/onboarding-chapter')

    // eslint-disable-next-line
  }, [isGGUDegreeDataLoading, isActiveGGUStudent, studentData])

  useEffect(() => {
    const { isCollegeSuccessCourse } = config
    if (!user || !studentData) return setComponent(null)

    const isAdmin = isOutlierAccount(email)
    if (isAdmin) return setComponent(null)

    const query = qs.parse(window.location.search.slice(1))
    const { courseId: queryCourseId } = query || {}

    const hasOnlyCollegeSuccessCourse = courses.length === 1 &&
      isCollegeSuccessCourse(courses[0].id)

    const hasProgressInAnyCourse = hasCourseProgress(coursesProgress)
    const shouldOnboard = queryCourseId
      ? !hasCurrentCourseProgress(coursesProgress, queryCourseId)
      : !hasProgressInAnyCourse

    if (shouldOnboard) {
      const {
        [EMAIL_VERIFIED]: studentDataEmailVerified, instrideId, partnerProgramId
      } = studentData || {}
      const emailVerified = auth0EmailVerified || studentDataEmailVerified

      const hasAtLeastOnePartnerAttempt = studentAttempts?.some(attempt => {
        const { fields: { relationshipName } } = attempt || {}
        return !!relationshipName
      })

      const shouldShowVerifyEmail = !emailVerified && !hasOnlyCollegeSuccessCourse &&
        !isGGUStudent && !hasAtLeastOnePartnerAttempt

      if (shouldShowVerifyEmail) {
        return setComponent(<VerifyEmailStudent studentEmail={email} />)
      }

      const showOnboardingForm =
        !onboardingFormSubmitted(studentData) &&
        ((!isGGUStudent && !instrideId) ||
          (instrideId && EXCLUDED_PARTNER_PROGRAMS.includes(partnerProgramId)))

      if (showOnboardingForm) {
        return setComponent(<OnboardingForm />)
      }
    }

    const {
      dateTOS,
      guardianTOSDate,
      under13,
      instrideId,
      dateOfBirth,
      notStudent: isNotStudent,
      vip: isVIP,
      existsInRegistration: isTypeFormRegistered,
      isProfCertRegistered
    } = studentData

    if (instrideId && !dateTOS) localStorage.setItem(HIDE_DASHBOARD_ITEM, true)

    if (under13) return setComponent(<Under13 />)

    const { showGuardianPermission, showTermsModal } = hasProgressInAnyCourse
      ? {}
      : showTermsAgreement({ courses, dateOfBirth, under13, dateTOS, guardianTOSDate })

    const permissionRequested = localStorage.getItem(PERMISSION_REQUESTED_KEY)
    if (showGuardianPermission) {
      return setComponent(
        <GuardianPermission
          initialState={permissionRequested ? PERMISSION_AWAITED : PERMISSION_REQUIRED}
        />
      )
    }

    setShowTOS(showTermsModal)

    const shouldShowForm =
      !isAdmin && isNotStudent === false && !isVIP && !isStudioCohort

    const isStudentNotRegistered = areAllCoursesProfCert
      ? !isProfCertRegistered
      : !isTypeFormRegistered

    const hasPaidCourses = getPaidCourses(courses).length > 0

    const shouldShowRegistrationForm =
      shouldShowForm &&
      isStudentNotRegistered &&
      !isActiveGGUStudent &&
      hasPaidCourses

    if (shouldShowRegistrationForm) {
      return setComponent(<RegistrationForm />)
    }

    const showWelcomePage = showTermsModal && !instrideId
    if (showWelcomePage) return setComponent(<WelcomePage over18 />)

    if (permissionRequested) {
      setComponent(<GuardianPermission initialState={PERMISSION_GRANTED} />)
      return
    }

    setComponent(null)

    // eslint-disable-next-line
  }, [user, studentData])

  useEffect(() => {
    if (courses && activeCourses) {
      const coursesWithLinks = getMoreCourses(courses, activeCourses).map((course) => {
        course.link = `/#/catalog/${course.id}`
        return course
      })
      setAdditionalCourses(coursesWithLinks)
    }
  }, [courses, activeCourses])

  if (!studentData) return <LoadingSpinner />

  if (showTOS) return <TermsAgreementModal show />

  if (component) {
    return (
      <>
        {component}
      </>
    )
  }

  if (showFailure) return <ErrorMessage errorType={FAILURE} />
  if (isEmpty(courses) || isContextLoading || (isActiveGGUStudent && isGGUDegreeDataLoading)) {
    return <LoadingSpinner />
  }

  const showGGUWelcomePage = isGGUStudent &&
    localStorage.getItem(GGU_WELCOME_PAGE_VIEW)
  if (showGGUWelcomePage) return <GGUWelcomePage />

  const showInstrideWelcomePage =
    instrideId && localStorage.getItem(INSTRIDE_WELCOME_PAGE_VIEW)
  if (showInstrideWelcomePage) return <InstrideWelcomePage />

  const studentEnrolled = isStudentEnrolled(courses)
  const onViewAllClick = () => {
    history.push('/my-courses')
  }

  const showMoreCourses = additionalCourses.length > 0

  const { firstName, preferredName } = studentData || {}

  const filteredCourses = courses?.filter((course) => !hiddenCourses?.includes(course?.id))

  function shouldShowEC () {
    const { confirmed } = JSON.parse(localStorage.getItem('enrollmentConfirmed')) || {}
    return !confirmed
  }

  if (
    showEC &&
    isActiveGGUStudent &&
    isGGUStudentEnrolled
  ) return <EnrollmentConfirmation setShowEC={setShowEC} prospects={prospects} />

  return (
    <>
      {config.hasDashboardRedesignFlag ? (
        <DashboardRedesign />
      ) : (
        <>
          <PageTitle title='Dashboard'>
            <WelcomeMessage
              userName={preferredName || firstName || 'student'}
            />
          </PageTitle>
          <PageContent>
            {<StudyBlocksDisplay />}

            {courses.length > 0 && (
              <WhatsNextWrapper data-testid='whats-next-widget'>
                {whatsNextUpdate && <WhatsNextUpdate courses={courses} />}
                {!whatsNextUpdate && <WhatsNext courses={filteredCourses} />}
              </WhatsNextWrapper>
            )}
            {!studentEnrolled && <NotEnrolled />}
            <DegreesPlus />
            {studentEnrolled && (
              <>
                <Row>
                  <CoursesTitleDiv xs={12} sm={2} md={2} lg={2}>
                    <CourseTitle data-testid='my-courses'>
                      My Courses
                    </CourseTitle>
                  </CoursesTitleDiv>
                  {courses.length > COURSE_DISPLAY_LIMIT && (
                    <ViewAllDiv xsm={1} sm={2} md={2} lg={2}>
                      <Link onClick={onViewAllClick}>VIEW ALL</Link>
                    </ViewAllDiv>
                  )}
                </Row>
                <CardsDiv data-testid='courses'>
                  <Courses list={take(courses, COURSE_DISPLAY_LIMIT)} />
                </CardsDiv>

                <MoreCoursesTitle data-testid='explore-more-courses-title'>
                  {showMoreCourses && 'You might also be interested in...'}
                </MoreCoursesTitle>
                {showMoreCourses && (
                  <>
                    <ExploreMoreCoursesWrapper>
                      <div data-testid='more-courses'>
                        <ExploreMoreCourses courses={additionalCourses} />
                      </div>
                    </ExploreMoreCoursesWrapper>
                    <ExploreCatalog onClick={() => history.push('/catalog')}>
                      <span>Explore Catalog</span>
                      <ArrowIcon />
                    </ExploreCatalog>
                  </>
                )}
              </>
            )}
          </PageContent>
        </>
      )}
      <VoucherUploadModal />
      <TuitionPaymentRequestModal />
    </>
  )
}

Dashboard.displayName = 'Dashboard'
export default Dashboard
