import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import config from '../../config'

const RedirectComponent = () => {
  const { slug } = useParams()
  const redirectUrl = `${config.apiHost}/course-download-redirect/${slug}`

  useEffect(() => {
    window.location.replace(redirectUrl)
  }, [redirectUrl])

  return null
}

export default RedirectComponent
