import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import api from '../../api'
import {
  GGU_WELCOME_PAGE_VIEW, INSTRIDE_WELCOME_PAGE_VIEW, TERMS_AGREEMENT
} from '../../Constants'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { Button, CheckBox, GlobalStyle, TitleWrapper } from './style'
import { EXCLUDED_PARTNER_PROGRAMS } from '../../Constants/instride'

const TermsAgreementModal = ({
  show
}) => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { studentData = {} } = useContext(AppContext)
  const { instrideId, partnerProgramId } = studentData

  const setTermsInStudentData = async () => {
    setIsLoading(true)
    const { success, message } = await api.setStudentData(
      TERMS_AGREEMENT, { [TERMS_AGREEMENT]: true }
    )

    if (!success) {
      setIsLoading(false)
      console.error('Error when setting terms agreement', message)
      return
    }

    const pageViewType =
      instrideId && !EXCLUDED_PARTNER_PROGRAMS.includes(partnerProgramId)
        ? INSTRIDE_WELCOME_PAGE_VIEW
        : GGU_WELCOME_PAGE_VIEW

    localStorage.setItem(pageViewType, true)

    setTimeout(() => window.location.reload(), 50)
  }

  return (
    <Modal
      show={show}
      centered
    >
      {isLoading && <LoadingSpinner />}
      <GlobalStyle />
      <TitleWrapper>
        terms agreement
      </TitleWrapper>
      <Modal.Body>
        <CheckBox data-testid='checkbox-terms'>
          By checking this box you acknowledge that you have read and agree to our&nbsp;
          <a href='#/terms-of-use' target='_blank' rel='noopener noreferrer'>
            Terms of Service
          </a> and&nbsp;
          <a href='#/privacy-policy' target='_blank' rel='noopener noreferrer'>
            Privacy Policy
          </a>.
          <p>
            Please note that these differ from the Terms of Service and Privacy Policy of our homepage,&nbsp;
            <a href='https://www.outlier.org/' target='_blank' rel='noopener noreferrer'>
              outlier.org
            </a>.
          </p>
          <input
            type='checkbox'
            data-testid='checkbox-terms-accepted'
            value={termsAccepted}
            onChange={() => setTermsAccepted(termsAccepted => !termsAccepted)}
          />
          <span />
        </CheckBox>
      </Modal.Body>
      <Button
        data-testid='btn-accept-terms'
        onClick={setTermsInStudentData}
        disabled={!termsAccepted}
      >
        continue
      </Button>
    </Modal>
  )
}

TermsAgreementModal.displayName = 'TermsAgreementModal'
export default TermsAgreementModal
