import React from 'react'
import {
  getCohortClosestExam,
  getCohortOpenExamDates
} from '../../../../utilities/cohort'
import {
  getExamFromExamNumber,
  getExamReviewChapter
} from '../../../../utilities/chapterUtils'
import { EXAM_COMPLETE } from '../../../../Constants/studentProgressKeys'
import { EXAM } from '../../../../Constants/chapterTypes'
import {
  daysToSeconds,
  getTimezoneShort,
  secondsToDateString,
  secondsToFormattedDateTimeShort
} from '../../../../utilities/dateTime'
import config from '../../../../config'
import { has } from 'lodash'
import { DAYS_BEFORE_EXAM } from '../../../../Constants'
import WhatsNextWidget from '../WhatsNextWidget'
import { getLatestCohort } from '../../../../utilities/course'
import api from '../../../../api'

function Exam ({ course, courseProgress, desktopHero }) {
  const { studentProgress } = courseProgress
  const { id: courseId, chapters } = course
  const cohort = getLatestCohort(course)
  const { courseBaseUrlById } = config
  const courseBaseUrl = courseBaseUrlById(courseId)

  const currentTime = Date.now()
  const examOpenData = getCohortOpenExamDates(cohort, currentTime)
  const { startDate, examNumber, isFinalExam } = getCohortClosestExam(cohort)
  const upComingExam = getExamFromExamNumber({
    chapters,
    examNumber,
    isFinalExam
  })

  const examsArray = chapters.filter((chapter) => chapter.type === EXAM)
  let stateData

  const getUpcomingExamNote = () => {
    const unlockDate =
      startDate &&
      secondsToFormattedDateTimeShort(startDate).replace(', ', ' at ')
    const timezone = getTimezoneShort(secondsToDateString(startDate))
    if (!unlockDate || !upComingExam) return
    let { title, isFinalExam } = upComingExam
    title = isFinalExam ? `The ${title}` : title
    return `${title} will open on ${unlockDate} ${timezone}`
  }

  const getStudyGuideNote = () => {
    if (!upComingExam) return
    const containsStudyGuide = has(upComingExam, 'examStudyGuide.file.url')

    if (!containsStudyGuide) {
      return {
        isStudyGuideOpen: false,
        studyGuideText:
          '. Visit the Course Home to begin reviewing the content.'
      }
    }

    const studyGuideOpenDate = startDate - daysToSeconds(DAYS_BEFORE_EXAM)
    const isStudyGuideOpen = currentTime > studyGuideOpenDate * 1000

    if (isStudyGuideOpen) {
      const { examStudyGuide } = upComingExam
      const { file: { url } = {} } = examStudyGuide || {}
      return {
        isStudyGuideOpen: true,
        studyGuideText: 'The study guide is available now.',
        studyGuideLink: url
      }
    }

    const formattedStudyGuideDate = secondsToFormattedDateTimeShort(
      studyGuideOpenDate
    ).replace(', ', ' at ')
    const timezone = getTimezoneShort(secondsToDateString(studyGuideOpenDate))

    return {
      isStudyGuideOpen: false,
      studyGuideText: `, and the study guide will become available on ${formattedStudyGuideDate}
        ${timezone}. In the meantime, visit the Course Home to begin reviewing the content.`,
      studyGuideLink: null
    }
  }

  const handleExamCompleted = (exam) => {
    const { isFinalExam } = exam

    if (!isFinalExam) return {}

    return {
      description:
        "There’s still time to go back and use up any remaining quiz attempts to try for perfect scores (if you don't have those already.",
      dataTestId: 'exam-complete',
      primaryButtonText: 'Visit home',
      primaryButtonFunction: () => {
        window.location.href = courseBaseUrl
      },
      caughtUpText: 'Nice work! You’ve reached the end of your coursework!'
    }
  }

  const handleUpcomingExam = () => {
    const {
      isStudyGuideOpen,
      studyGuideText,
      studyGuideLink
    } = getStudyGuideNote()
    const examReviewChapter = getExamReviewChapter(upComingExam, chapters)
    const reviewFirstSectionUUID =
      examReviewChapter?.sections?.[0]?.['section_uuid']

    const description = getUpcomingExamNote()
    const { isFinalExam } = upComingExam
    const caughtUpText = isFinalExam
      ? 'Nice work! You’ve completed all of your coursework.'
      : 'You’re caught up! The next set of activities will unlock after the exam.'

    if (reviewFirstSectionUUID) {
      const { title } = upComingExam
      return {
        description: `${description}. Brush up on the material or start the review chapter in preparation for the ${title}.`,
        primaryButtonText: 'Visit home',
        secondaryBtnFunction: () => {
          window.location.href = courseBaseUrl
        },
        caughtUpText
      }
    }

    if (isStudyGuideOpen) {
      const { chapter_uuid: chapterId } = upComingExam
      return {
        description: `${description}. ${studyGuideText}`,
        primaryButtonText: 'View study guide',
        primaryButtonFunction: async () => {
          if (!studyGuideLink || !chapterId) return
          await api.incrementStudyGuideClicks(courseId, chapterId)
          window.open(studyGuideLink, '_blank')
        },
        caughtUpText
      }
    }

    if (!isStudyGuideOpen) {
      return {
        description: `${description}${studyGuideText}`,
        primaryButtonText: 'Visit home',
        primaryButtonFunction: () => {
          window.location.href = courseBaseUrl
        },
        caughtUpText
      }
    }
  }

  if (upComingExam && !examOpenData) {
    stateData = handleUpcomingExam()
  }

  if (examOpenData) {
    const { examOpen, isFinalExam } = examOpenData
    const currentExam = getExamFromExamNumber({
      chapters: examsArray,
      examNumber: examOpen,
      isFinalExam
    })
    if (!currentExam) return null

    const { chapter_uuid: chapterUuid } = currentExam

    const examComplete = studentProgress[EXAM_COMPLETE]
    const isExamCompleted = chapterUuid in examComplete

    stateData = isExamCompleted ? handleExamCompleted(currentExam) : {}
  }
  return <WhatsNextWidget {...stateData} desktopHero={desktopHero} />
}

Exam.displayName = 'Exam'
export default Exam
