export const publicRoutes = [
  '/guardian-permission',
  '/terms-of-use',
  '/privacy-policy',
  '/audience-consent',
  '/login'
]

export const isPublicRoute = (pathName) => {
  if (!pathName) {
    pathName = window.location.hash.split('#')[1]
  }

  return publicRoutes.includes(pathName)
}
