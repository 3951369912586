import React, { useContext, useState } from 'react'
import api from '../../api'
import EditEmailSection from './EditEmail'
import PageItemContent from './PageItem'
import { EMAIL, PASSWORD } from '../../Constants/accountArea'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import AlertModal from '../AlertModal/AlertModal'
import {
  EditButton, ItemContainer, ItemContentContainer
} from './style'
import { useAuthStore, useUserStore } from '@outlier-org/lst-auth-react'

function LoginSecurityPage () {
  const user = useUserStore(state => state.user)
  const isSsoUser = useAuthStore(state => state.isSso)
  const { isContextLoading } = useContext(AppContext) || {}

  const [showAlert, setShowAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState({})

  const [activeEditingItem, setActiveEditingItem] = useState('')
  const [isEditingEmail, setIsEditingEmail] = useState(false)

  const isEditingActive = isEditingEmail

  const shouldShowEditButton = (itemName) => {
    // If the user is an SSO user, they can't edit their email
    if (isSsoUser) {
      return false
    }

    if (!isEditingActive) return true

    return itemName === activeEditingItem
  }

  const handleEditEmailButton = () => {
    setActiveEditingItem(EMAIL)
    setIsEditingEmail(!isEditingEmail)
  }

  const getItemOpacity = (itemName) => {
    if (isEditingActive) {
      if (itemName === activeEditingItem) return '1'
      return '.3'
    }

    return '1'
  }

  const changePassword = async (email) => {
    const response = await api.changePassword(email) || {}
    const { overrideStudentEmail } = response
    if (overrideStudentEmail) return
    setAlertInfo(response)
    setShowAlert(true)
  }

  const closeModal = () => setShowAlert(false)

  if (isContextLoading) return <LoadingSpinner />

  return (
    <div
      data-testid='login-security-page'
    >
      <ItemContainer
        opacity={getItemOpacity(EMAIL)}
      >
        <ItemContentContainer>
          <PageItemContent
            title={EMAIL}
            content={<span className='fs-exclude'>{user.email}</span>}
            tooltipText='We’ll use this email for your login and for any course-related communication.'
          />

          {shouldShowEditButton(EMAIL) && (
            <EditButton
              data-testid='edit-email-section-button'
              onClick={() => handleEditEmailButton(EMAIL)}
            >
              {isEditingEmail ? 'cancel' : 'edit'}
            </EditButton>
          )}
        </ItemContentContainer>

        {isEditingEmail && (
          <EditEmailSection
            userEmail={user.email}
          />
        )}
      </ItemContainer>

      <ItemContainer
        opacity={getItemOpacity(PASSWORD)}
      >
        <ItemContentContainer>
          <PageItemContent
            title={PASSWORD}
            content='*****'
          />

          {shouldShowEditButton(PASSWORD) && (
            <EditButton
              data-testid='update-password-button'
              onClick={() => changePassword(user.email)}
            >
              update
            </EditButton>
          )}
        </ItemContentContainer>
      </ItemContainer>

      <AlertModal
        show={showAlert}
        title={alertInfo.title}
        titleIcon={alertInfo.titleIcon}
        text={alertInfo.text}
        buttonText={alertInfo.buttonText}
        onHide={closeModal}
        onButtonClick={closeModal}
      />
    </div>
  )
}

export default LoginSecurityPage
