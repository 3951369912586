import React from 'react'
import { useAuthStore } from '@outlier-org/lst-auth-react'
import { isPublicRoute } from '../../utilities/authUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

const AppGuard = ({ children }) => {
  const [isAuthenticated, login, isLoading] = useAuthStore((state) => [state.isAuthenticated, state.login, state.isLoading])

  if (isPublicRoute() || isAuthenticated) {
    return children
  }

  if (!isLoading) {
    login()
  }

  return <LoadingSpinner />
}

export default AppGuard
