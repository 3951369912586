import React, { useEffect, useState, useRef } from 'react'
import { addInfoForCourses } from '../../utilities/chapterUtils'
import {
  addTokensToCourses,
  getLatestCohort,
  isStudioCohort as isStudioCohortFunction,
  getCoursesWithAccess,
  getProjectedFinalGradeText,
  getCoursesProgress,
  addCompletedCourses,
  sortCoursesByProgress,
  groupAndFilterCourses,
  filterOutGGUCourses,
  areAllCoursesProfessionalCertificate
} from '../../utilities/course'
import { getStudentAge, isConsultant, isOutlierAccount } from '../../utilities/user'
import { DEGREE_REGISTRATION_INCOMPLETE, EMAIL_VERIFIED } from '../../Constants'
import api from '../../api'
import config from '../../config'
import { VOUCHER_STATUS } from '../../Constants/voucher'
import { isAmazonStudent } from '../../utilities/voucher'
import { filterCertificates } from '../../utilities/certificate'
import { ACTIVE } from '../../Constants/degreeStatus'
import {
  APPLIED_COMPUTING,
  LIBERAL_STUDIES,
  BUSINESS_ADMINISTRATION
} from '../../Constants/gguDegrees'
import {
  showGGUEnrollment as showGGUEnrollmentFunction,
  getCurrentGGUSemester,
  isEnrolledInCurrentSemester,
  canStudentEnrollInUPJCourses,
  updateDegreeDataToCWIV2
} from '../../utilities/gguDegreeUtils'
import { getFilteredTokens } from '../../utilities/tokenUtils'
import { OFFER_NOT_ACCEPTED } from '../../Constants/prospectStatus'
import { useAuthStore, useUserStore } from '@outlier-org/lst-auth-react'

const qs = require('qs')

export const AppContext = React.createContext()
const ContextProvider = ({ children }) => {
  const [user, setUser] = useUserStore((state) => [state.user, state.setUser])
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const [context, setContext] = useState({
    isGGUDegreeDataLoading: true, quitGGUEnrolment: true, studyBlocksData: {}
  })
  const [courses, setCourses] = useState(undefined)
  const [certificates, setCertificates] = useState([])
  const [outlierCourses, setOutlierCourses] = useState([])
  const [activeCourses, setActiveCourses] = useState(undefined)
  const [showVoucherPopup, setShowVoucherPopup] = useState(true)
  const [studentAttempts, setStudentAttempts] = useState(undefined)
  const [studentCourses, setStudentCourses] = useState(undefined)
  const [pretestAttempts, setPretestAttempts] = useState(undefined)
  const [pretestOverrides, setPretestOverrides] = useState(undefined)
  const [tokens, setTokens] = useState([])
  const [catalogCourses, setCatalogCourses] = useState([])
  const [isStudioCohort, setIsStudioCohort] = useState(false)
  const [courseProjectedGrades, setCourseProjectedGrades] = useState(null)
  const [courseCurrentGrades, setCourseCurrentGrades] = useState(null)
  const [vouchers, setVouchers] = useState({
    isLoading: false,
    records: null
  })
  const [cohortOriginalDeadlines, setCohortOriginalDeadlines] = useState([])
  const [isContextLoading, setIsContextLoading] = useState(true)
  const [hiddenCourses, setHiddenCourses] = useState([])
  const [isReloadContext, setIsReloadContext] = useState(false)
  const [showTuitionPaymentRequestModal, setShowTuitionPaymentRequestModal] = useState(true)
  const isAdmin = isOutlierAccount(user?.email || '')
  const isFirstTime = useRef(true)
  const [prospects, setProspects] = useState(undefined)

  const fetchGrades = async courses => {
    const filteredCourses = courses.filter(course => { return getProjectedFinalGradeText(course) })
    const { email } = user
    const promises = filteredCourses.map(async (course) => {
      const { id: cohortID, name: cohortName } = getLatestCohort(course)
      const courseID = course.id
      const projectedGrade = api.getProjectedGrade(courseID, cohortID, email)
      const currentGrade = api.getStudentCurrentGrade(courseID, cohortName, email)

      return Promise.all([projectedGrade, currentGrade])
    })
    const courseProjectedFinalGrades = {}
    const courseCurrentGrades = {}
    const results = await Promise.all(promises)
    filteredCourses.forEach(
      (course, i) => {
        courseProjectedFinalGrades[course.id] = results[i][0]
        const currentGradeRow = results[i][1]
        courseCurrentGrades[course.id] = Object.values(currentGradeRow)?.[0]
      })
    setCourseProjectedGrades(courseProjectedFinalGrades)
    setCourseCurrentGrades(courseCurrentGrades)
  }

  const setStudentData = async (studentData) => {
    const query = qs.parse(window.location.search.slice(1))
    const verificationEmailClicked = !!query[EMAIL_VERIFIED]
    const { [EMAIL_VERIFIED]: emailVerified } = studentData || {}
    if (!verificationEmailClicked || emailVerified) return

    const { id: appStudentId } = studentData || {}
    const { studentId } = query || {}
    if (studentId !== appStudentId) return

    const { success, message } = await api.setStudentData(
      EMAIL_VERIFIED, { [EMAIL_VERIFIED]: true }
    )
    if (!success) return console.error('ContentRouter: Error when setting student data', message)

    window.location.reload()
  }

  useEffect(() => {
    if (!courses?.length) return

    const isNotAmazonStudent = !isAmazonStudent(courses)
    const shouldNotCallAmazonEndpoint = isNotAmazonStudent
    if (shouldNotCallAmazonEndpoint) return

    fetchAmazonVouchers()
  }, [courses])

  const fetchAmazonVouchers = async () => {
    setVouchers({
      isLoading: true,
      records: null
    })

    const vouchers = await api.getAmazonVouchers()

    setVouchers({
      isLoading: false,
      records: vouchers
    })
  }

  const updateVoucherStatus = (voucherId, voucherNumber) => {
    const { records } = vouchers
    if (!records?.length || !voucherId) return

    const updatedVouchers = records.map(voucher => {
      if (voucher.voucherId !== voucherId) return voucher

      return {
        ...voucher,
        pdfVoucherNumber: voucherNumber,
        voucherStatus: VOUCHER_STATUS.PENDING_APPROVAL
      }
    })

    setVouchers({
      isLoading: false,
      records: updatedVouchers
    })
  }

  const courseFeatures = async () => {
    const result = await api.getCourseFeatures()
    setHiddenCourses(result?.['dashboard-course-hidden'])
  }

  useEffect(() => {
    if (!courses) return
    fetchGrades(courses)
    // eslint-disable-next-line
  }, [courses])

  const fetchCohortOriginalDeadlines = async () => {
    const data = await api.getCohortOriginalDeadlines()
    setCohortOriginalDeadlines(data)
  }

  useEffect(() => {
    if (isAdmin || !user.email) return
    fetchCohortOriginalDeadlines()
    // eslint-disable-next-line
  }, [isAdmin, user.email])

  useEffect(() => {
    if (!courses?.length) return

    let isStudioCohort = true
    courses.forEach(course => {
      isStudioCohort = isStudioCohort && isStudioCohortFunction(course)
    })
    setIsStudioCohort(isStudioCohort)
  }, [courses])

  useEffect(() => {
    (async () => {
      const { studentId } = user

      if (!isAuthenticated || !studentId) {
        return
      }

      setIsContextLoading(true)
      setContext(prev => ({
        ...prev,
        isGGUDegreeDataLoading: true,
        canStudentEnrollInUPJCourses: true
      }))
      try {
        const [
          examRetakes,
          studentCourses,
          activecourses,
          studentTokens,
          studentAttempts,
          studentData,
          prospects,
          gguAdvisorApprovalRequired,
          catalogueData,
          studentCertificates,
          outlierCourses,
          studyBlocksData,
          pretestAttempts,
          pretestOverrides
        ] = await Promise.all([
          api.getExamRetakes(),
          api.getStudentCourses(isConsultant(user?.email)),
          api.getActiveCourses(),
          api.getAllTokens({ activityLog: config.showTokenActivityLog() }),
          api.getStudentAttempts(),
          api.getStudentData(),
          api.getProspectsData(),
          api.getGGUAdvisorApprovalRequired(),
          api.getCatalogCourses(),
          api.getStudentCertificates(),
          api.getOutlierCourses(),
          api.getStudyBlocks(),
          api.getPretestAttempts('attempts'),
          api.getPretestAttempts('overrides')
        ])

        setUser({
          ...user,
          email: studentData?.email
        })

        setStudentCourses(studentCourses)
        Array.isArray(activecourses) && setActiveCourses(activecourses)
        setTokens(getFilteredTokens(studentTokens))
        setStudentAttempts(studentAttempts)
        setCertificates(filterCertificates(studentCertificates))
        setCatalogCourses(catalogueData)
        setOutlierCourses(outlierCourses)
        setPretestAttempts(pretestAttempts)
        setPretestOverrides(pretestOverrides)
        setProspects(prospects)

        setContext(prev => ({
          ...prev,
          examRetakes,
          gguAdvisorApprovalRequired,
          studentData,
          studyBlocksData,
          prospects
        }))
      } catch (error) {
        console.log('error from Context: ', error)
        setContext(prev => ({ ...prev, showFailure: true }))
      } finally {
        setIsContextLoading(false)
      }
    })()
  // eslint-disable-next-line
  }, [isReloadContext, user.studentId])

  useEffect(() => {
    if (!studentCourses || !tokens || !studentAttempts || !prospects) return

    (async () => {
      const courses = addTokensToCourses(studentCourses?.courses, tokens)
      const coursesWithId = courses?.filter(course =>
        course.id !== undefined &&
        course.id !== 'test-course'
      )

      const coursesWithAccess =
        config.hasUseAttempt && !isAdmin
          ? groupAndFilterCourses(coursesWithId)
          : getCoursesWithAccess(coursesWithId)

      const coursesWithChapterInfo = await addInfoForCourses(coursesWithAccess)
      const currentCourses = sortCoursesByProgress(coursesWithChapterInfo)

      const coursesProgress = await getCoursesProgress(coursesWithAccess)

      const completedAttemptsCourses = config.hasUseAttempt
        ? []
        : addCompletedCourses(studentAttempts, currentCourses)
      const allCourses = currentCourses.concat(completedAttemptsCourses)
      const prospectStatus = prospects?.[0]?.prospectStatus
      const isOfferNotAccepted = prospectStatus === OFFER_NOT_ACCEPTED
      const filteredCourses = isOfferNotAccepted
        ? filterOutGGUCourses(allCourses, prospectStatus)
        : allCourses

      setCourses(filteredCourses)
      setContext(prev => ({ ...prev, coursesProgress }))
    })()

    // eslint-disable-next-line
  }, [studentCourses, tokens, studentAttempts, prospects])

  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false
      return
    }

    if (!context.prospects?.length) {
      return setContext(prev => ({
        ...prev,
        isGGUDegreeDataLoading: false
      }))
    }

    const { prospects } = context
    const { degreeStatus, gguSemesters } = prospects?.[0] || {}

    setContext(prev => ({
      ...prev,
      degreeStatus,
      ...getGGUStudentStatus(prospects)
    }));

    (async () => {
      const [degreeData, allGGUSemesters] = await Promise.all([
        getGGUDegreeRecs(prospects),
        api.getAllGGUSemesters()
      ])

      const currentSemester = getCurrentGGUSemester({
        allGGUSemesters, studentAssignedSemesters: gguSemesters
      })

      updateDegreeDataToCWIV2(degreeData, currentSemester)

      const isGGUStudentEnrolled = isEnrolledInCurrentSemester({
        currentSemester, studentAttempts, degreeData
      })
      if (isGGUStudentEnrolled) {
        if (context?.studentData?.[DEGREE_REGISTRATION_INCOMPLETE]) {
          setContext({
            studentData: {
              ...context?.studentData,
              [DEGREE_REGISTRATION_INCOMPLETE]: false
            }
          })
          api.setStudentData(
            DEGREE_REGISTRATION_INCOMPLETE,
            { [DEGREE_REGISTRATION_INCOMPLETE]: false }
          )
        }
      }

      setContext(prev => ({
        ...prev,
        degreeData,
        isGGUStudentEnrolled,
        currentSemester,
        showGGUEnrollment: showGGUEnrollmentFunction({
          prospects,
          currentSemester,
          isGGUStudentEnrolled,
          isActiveGGUStudent: prev.isActiveGGUStudent
        }),
        canStudentEnrollInUPJCourses: canStudentEnrollInUPJCourses({
          outlierCourses,
          currentSemester,
          studentAttempts,
          degreeData,
          isActiveGGUStudent: prev.isActiveGGUStudent
        }),
        isGGUDegreeDataLoading: false
      }))
    })()
  // eslint-disable-next-line
  }, [context.prospects])

  useEffect(() => {
    if (!context.studentData) return
    setStudentData(context.studentData)
  }, [context.studentData])

  const getGGUDegreeRecs = async (prospectsData) => {
    const { degree } = prospectsData?.[0] || {}
    switch (degree) {
      case BUSINESS_ADMINISTRATION:
        return api.getGGUdegreeRec('bus-admin-rec')
      case APPLIED_COMPUTING:
        return api.getGGUdegreeRec('app-comp-rec')
      case LIBERAL_STUDIES:
        return api.getGGUdegreeRec('liberal-studies-rec')
      default : return null
    }
  }

  const getGGUStudentStatus = prospects => {
    const {
      applicationSubmitted,
      dateOfBirth,
      degreeStatus,
      prospectStatus
    } = prospects?.[0] || {}

    const isGGUStudent = applicationSubmitted
    const isOfferNotAccepted = prospectStatus === OFFER_NOT_ACCEPTED
    const isActiveGGUStudent = isGGUStudent && degreeStatus === ACTIVE &&
      !isOfferNotAccepted
    const isUnder18 = getStudentAge(dateOfBirth) < 18

    return {
      isGGUStudent,
      isUnder18,
      isActiveGGUStudent
    }
  }

  const areAllCoursesProfCert = areAllCoursesProfessionalCertificate(courses)

  const reloadContext = () => {
    setIsReloadContext(!isReloadContext)
  }

  return (
    <AppContext.Provider value={{
      courses,
      outlierCourses,
      catalogCourses,
      certificates,
      studentAttempts,
      pretestAttempts,
      pretestOverrides,
      tokens,
      showFailure: false,
      courseProjectedGrades,
      courseCurrentGrades,
      showVoucherPopup,
      setShowVoucherPopup,
      vouchers,
      updateVoucherStatus,
      cohortOriginalDeadlines,
      ...context,
      isContextLoading,
      hiddenCourses,
      hideCatalogMenu: false,
      courseFeatures,
      areAllCoursesProfCert,
      // If all the latest cohorts are of access type studio, this will be true
      isStudioCohort,
      reloadContext,
      activeCourses,
      showTuitionPaymentRequestModal,
      setShowTuitionPaymentRequestModal,
      setStudentCourses,
      setStudentAttempts,
      setTokens,
      updateContext: newContext => setContext({ ...context, ...newContext })
    }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextProvider
