import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { AppContext } from '../ContextProvider/ContextProvider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import api from '../../api'
import { getSectionsData } from './utils/sectionData'
import caret from '../../assets/icons/caret.svg'
import {
  EmailText,
  EmailTextWrapper,
  BreakLine,
  FormHeading,
  ContactWrapper,
  ContactHeader,
  SecondaryHeading,
  Span,
  Form,
  FormTextArea,
  SubmitButton,
  // EmailJoinDiv,
  // JoinButton,
  FormDiv,
  SubmitButtonDiv,
  SectionTitle,
  SectionWrapper,
  SectionDescription,
  Tile,
  TileContent,
  TileTitle,
  TileDescription
  // CardDiv,
  // Card,
  // SupportText,
  // EmailInput,
  // Paragraph,
} from './style'
import { GGU_RELATIONSHIP } from '../../Constants/relationships'
import { getCurrentProspect } from '../../utilities/gguDegreeUtils'
import { useUserStore } from '@outlier-org/lst-auth-react'

const Contact = () => {
  const user = useUserStore((state) => state.user) || {}
  const { studentId } = user
  const location = useLocation()
  const history = useHistory()
  const {
    isStudioCohort,
    isActiveGGUStudent,
    prospects,
    isUnder18,
    isContextLoading,
    courses
  } = useContext(AppContext) || {}
  const [formText, setFormText] = useState('')
  const [loading, setLoading] = useState(false)
  // const navigate = (route) => window.open(`#${route}`, '_blank', 'noopener noreferrer')

  useEffect(() => {
    if (isContextLoading || !location?.search) return

    const { section } = queryString.parse(location.search)
    const sectionByQueryParam = document.querySelector(`#${section}`)
    if (!sectionByQueryParam) return

    sectionByQueryParam.scrollIntoView()

    // eslint-disable-next-line
  }, [isContextLoading])

  const sections = getSectionsData(prospects)
  const onSubmitContactForm = async () => {
    setLoading(true)
    try {
      await api.sendContactEmail(studentId, formText)
    } catch (e) {
      console.log('Error upon Contact Form Submission', e)
    } finally {
      setLoading(false)
      setFormText('')
    }
  }

  const isContactBtnDisabled = !formText || !studentId || loading

  const showAdvisorLink = () => {
    const isAtLeastOneGGUCourse = courses?.some(
      ({ name }) => name.includes(GGU_RELATIONSHIP)
    )
    if (isAtLeastOneGGUCourse) return true

    const { gguSemesters } = getCurrentProspect(prospects) || {}
    const { openEnrollment } = gguSemesters?.[0] || {}
    const isEnrollmentOpen = openEnrollment &&
      new Date(openEnrollment).getTime() < new Date().getTime()

    return isEnrollmentOpen
  }

  if (isStudioCohort) {
    return <Redirect to='/' />
  }

  if (isContextLoading) return <LoadingSpinner />

  return (
    <ContactWrapper isGGUStudentView={isActiveGGUStudent}>
      <ContactHeader>
        <SecondaryHeading>Contact</SecondaryHeading>
        {!isActiveGGUStudent && (
          <EmailTextWrapper>
            <EmailText>Student Support:</EmailText>
            <Span>
              <EmailText>success@outlier.org</EmailText>
            </Span>
          </EmailTextWrapper>
        )}
      </ContactHeader>
      <BreakLine isGGUStudentView={isActiveGGUStudent} />
      {isActiveGGUStudent ? sections.map((section, index) => {
        const { title, id, icon, tiles, description } = section

        return (
          <SectionWrapper
            {...(id && { id })}
            key={index}
            data-testid='section-wrapper'
          >
            <SectionTitle>
              <img src={icon} alt={title} />
              {title}
            </SectionTitle>
            {description && (
              <SectionDescription>{description}</SectionDescription>
            )}
            {tiles.map((tile, i) => {
              const { tileTitle, tileDescription, link, mailTo, _self } = tile

              if (isUnder18 && tileTitle === 'Virtual Campus') return null
              if (tileTitle === 'Advisor Contact' && !showAdvisorLink()) {
                return null
              }

              return (
                <Tile
                  data-testid='tile'
                  key={`${title} - ${i}`}
                  onClick={() => {
                    if (_self) return history.push(link)
                    window.open(
                      mailTo ? `mailto:${mailTo}` : link,
                      '_blank'
                    )
                  }}
                >
                  <TileContent>
                    <TileTitle>{tileTitle}</TileTitle>
                    <TileDescription>{tileDescription}</TileDescription>
                  </TileContent>
                  <img src={caret} alt='caret' />
                </Tile>
              )
            })}
          </SectionWrapper>
        )
      }) : null}
      {!isActiveGGUStudent && (
        <Form data-testid='form'>
          <FormDiv>
            <FormHeading>What can we help you with?</FormHeading>
            <FormTextArea
              data-testid='contact-textarea'
              onChange={e => setFormText(e.target.value)}
              value={formText}
            />
            <SubmitButtonDiv>
              <SubmitButton onClick={onSubmitContactForm} disabled={isContactBtnDisabled}>
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'submit'}
              </SubmitButton>
            </SubmitButtonDiv>
          </FormDiv>
        </Form>
      )}
    </ContactWrapper>
  )
}

export default Contact
