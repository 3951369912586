import React, { useEffect, useRef } from 'react'
import * as typeformEmbed from '@typeform/embed'
import { FormPageContainer, FormWrapper } from './style'
import { AMAZON_REFUND_REQUEST_PAGES } from '../../../Constants/accountArea'
import { getAmazonRefundFormUrl } from '../../../utilities/accountArea'
import { useUserStore } from '@outlier-org/lst-auth-react'

function AmazonRefundFormPage (props) {
  const user = useUserStore((state) => state.user) || {}
  const { email } = user

  const { studentCourses, studentData, setCurrentPage } = props

  const { firstName, lastName, name } = studentData || {}
  const studentName = firstName ? `${firstName} ${lastName}` : name || ''

  const formRef = useRef(null)

  useEffect(() => {
    if (!formRef.current) return
    embedForm()
  // eslint-disable-next-line
  }, [])

  const embedForm = () => {
    const amazonRefundFormUrl = getAmazonRefundFormUrl({
      studentCourses, studentEmail: email, studentName
    })

    const handleFormOnSubmit = () => setTimeout(() => {
      setCurrentPage(AMAZON_REFUND_REQUEST_PAGES.REQUEST_RECEIVAL)
    }, 3000)

    const transferableUrlParameters = [
      'student_name', 'student_email', 'refund_amount', 'latest_refund_date',
      'earliest_drop_date', 'required_course', 'math_elective', 'liberal_arts_elective',
      'business_elective', 'refund_eligible'
    ]

    typeformEmbed.makeWidget(
      formRef.current,
      amazonRefundFormUrl,
      {
        onSubmit: handleFormOnSubmit,
        transferableUrlParameters
      }
    )
  }

  return (
    <FormPageContainer>
      <FormWrapper ref={formRef} />
    </FormPageContainer>
  )
}

export default AmazonRefundFormPage
