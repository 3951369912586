import { Card, Row } from 'react-bootstrap'
import styled, { css, createGlobalStyle } from 'styled-components'
import media from '../../../mediaQueries'
import BackBreadcrumb from '../../BackBreadcrumb/BackBreadcrumb'
import PageTitle from '../../PageTitle/PageTitle'
import { ReactComponent as MajorsSVG } from '../../../assets/icons/courseGroups/major-courses.svg'
import { ReactComponent as MathSVG } from '../../../assets/icons/courseGroups/mathematics.svg'
import { ReactComponent as SocialSciencesSVG } from '../../../assets/icons/courseGroups/social-science.svg'
import { ReactComponent as CommunicationSVG } from '../../../assets/icons/courseGroups/communications.svg'
import { ReactComponent as ProblemSolvingSVG } from '../../../assets/icons/courseGroups/problem-solving.svg'
import { ReactComponent as Electives } from '../../../assets/icons/courseGroups/electives.svg'
import { ReactComponent as ChevronsSVG } from '../../../assets/icons/chevrons-icon.svg'
import {
  IN_PRODUCTION,
  COMPLETED,
  HAS_PRE_REQUISITE,
  PREPARE_CALCULUS,
  COMPLETED_NOT_REPEATABLE
} from '../../../Constants/CourseStatus'
import { font14x17x700 } from '../../global.styled'

export const OverrideStyles = createGlobalStyle`
  #content {
    max-width: 1366px;
    min-width: 460px;
    margin: 0 auto;
    ${media.mobile`
      padding: 0 17px;
    `}
  }

`

export const PageWrapper = styled.div`
  padding: 0 103px;
  ${props => props.isGGUStudentView && 'padding: 0 64px'};
  ${media.mobile`
    padding: 0px 15px;
    ${props => (props.$hasPracExerciseFlag && 'margin-top: 109px;')}}
  `}

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${media.mobile`
      display: block;
    `}
  }
`

export const TokenWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
  ${media.mobile`
    width: 100%;
  `}
  p {
    margin: 0px;
    margin-left: ${({ hideBag }) => hideBag ? '12px' : '6px'};
    margin-top: 3px;
  }
  img {
    color: #5fc4b8;
    width: 20px;
    height: 24px;
    cursor: pointer;
  }
  .bag-wrap {
    margin-left: 24px;
    position: relative;
    ${media.mobile`
      margin-left: auto;
    `}
    span {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #5fc4b8;
      margin: 0px;
      position: absolute;
      left: 7.5px;
      top: 9px;
    }
  }
`

export const CardCol = styled.div`
  padding: 0px 16px;
  width: 33.3%;

  @media (max-width: 768px) {
    width: 50%;
  }

  ${media.smallMobile`
    width: 100%;
  `}
`
export const CardCourseDescriptionWrapper = styled.div``

export const CardImageTextWrapper = styled.div`
  position: absolute;
  margin-bottom: 0;
  width: 90%;
  left: 24px;
  bottom: 24px;
`
export const CardImageWrapper = styled.div`
  position: relative;
  height: 288px;
  background-image: ${({ src }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0) 49.78%, #000000 100%),
     url(${src});
  `};
  background-size: cover;
  background-position: center center;
  border-radius: 5px;
  ${({ showBorder }) => showBorder && 'border: 2px solid #fff;'}

  ${media.mobile`
    height: 224px;
  `}

  ${media.smallMobile`
    height: 216px;
  `}

  &.active {
    border: 2px solid #5fc4b8;
  }
`

export const CardWrapper = styled(Card)`
  cursor: pointer;
  background-color: #161618;
  border: none;
  margin-top: 11px;
  margin-bottom: 11px;
  border: 2px solid #161618;
  transition: all 0.2s;
`

export const CourseDescription = styled.div``

export const CourseIcon = styled.span`
  background-color: #fff;
  width: 30px;
  height: 29px;
  mask: ${({ src }) => `
   url(${src}) no-repeat 1px 0;
 `};
  mask-size: contain;
  margin: 0px;
  position: absolute;
  right: 26px;
  top: 24px;
`

export const CourseTextWrapper = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
`
export const CourseDescriptionWrapper = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  margin-top: 6px;
  color: #b1bfc5;
  p {
    margin: 0px;
  }
`

export const CaptionContainer = styled.div`
  display: flex;
`

export const Caption = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: 12px;
  margin-bottom: 0;

  &.dot {
    margin: 7px 6px 0;
  }
`

export const StyledRow = styled(Row)`
  margin-left: -16px;
  margin-right: -16px;
`

export const FinalGradeText = styled.span`
  position: absolute;
  top: 24px;
  left: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  .taken {
    text-transform: capitalize;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  svg {
    margin-right: 6px;
    font-size: 16px;
  }
  .checkIcon{
    margin-right: 6px;
    margin-top: -1px;
  }
`

export const PreviousTitle = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 24px;
  padding: 0px;
`
export const BreadCrumbComponent = styled(BackBreadcrumb)`
  padding-left: 2px;
`
export const PageTitleComponent = styled(PageTitle)`
  padding-top: 32px;
  padding-bottom: 32px;
`
export const PageContentComponent = styled.div`
  margin-top: 32px;
`
export const GroupWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  background-color: #161618;
  background: #0D0D0F;
  border-radius: 5px;
  margin-bottom: 24px;
`
export const GroupHeaderMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const GroupHeaderMessageContainer = styled.div`
  display: flex;
  padding: 19px 16px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 5px;
  background-color: #1D1D1F;
`
export const GroupHeaderMessage = styled.span`
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const GroupHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`
export const GroupTitle = styled.h3`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin: 0;

  ${props => props.isOtherCoursesGroup && css`
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  `}
`
export const GroupDescription = styled.p`
  width: 100%;
  margin-top: 12px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
`
export const MathematicsIcon = styled(MathSVG)`
  display: inline-block;
  margin-right: 16px;
`
export const CommunicationIcon = styled(CommunicationSVG)`
  display: inline-block;
  margin-right: 16px;
`
export const MajorsIcon = styled(MajorsSVG)`
  display: inline-block;
  margin-right: 16px;
`
export const ElectivesIcon = styled(Electives)`
  display: inline-block;
  margin-right: 16px;
`
export const ProblemSolvingIcon = styled(ProblemSolvingSVG)`
  display: inline-block;
  margin-right: 16px;
`
export const SocialSciencesIcon = styled(SocialSciencesSVG)`
  display: inline-block;
  margin-right: 16px;
`
export const Required = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFFFFF;
`
export const GGUCourseCard = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 16.31px;
  align-items: center;
  padding: 17.5px;
  width: 47%;
  height: 110px;
  background-repeat: no-repeat;
  margin-bottom: 16.31px;
  border-radius: 5px;
  ${({ src }) => src ? css`
    background-image: ${({ src }) => src && `url(${src})`};
  ` : css`background-color: #2b2d31;`}
  background-size: 75% auto;
  background-position: 166% 24%;
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.7);
  cursor: pointer;
  user-select: none;

  ${props => props.isActive && css`
    :hover {
      border: solid 1px #5FC4B8;
    }

    :active {
      border: solid 1px #FFFFFF;
    }
  `}

  ::after {
    position: absolute;
    z-index: 0;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // a liner gradient to cover about half the image horizontally
    background-image: linear-gradient(90deg, black 0%, black 30%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) 100%);
    border-radius: 5px;
  }

  ${media.tablet`
    background-size: 109% auto;
    background-position: -165% 33%;
  `}

  ${media.mobile`
    width: 100%;
    background-size: 58% auto;
    background-position: 125% 24%;
  `}
`
export const CourseIconV2 = styled.div`
  position: relative;
  z-index: 1;
  width: 36px;
  height: 36px;
  margin-right: 17.43px;
  mask: ${({ src }) => `url(${src})`};
  mask-size: contain;
  mask-repeat: no-repeat;
  background: #CCD4D8;

  ${media.tablet`display: none;`}
  ${media.mobile`display: flex;`}
`
export const CardInfo = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-items: center;
`
export const CourseTitle = styled.h4`
  font-family: 'Lato';
  margin: 0;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
`
export const CourseSubtitle = styled.p`
  margin: 0;
  margin-bottom: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #98A4AE;

  ${media.tablet`
    width: 230px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  `}

  ${media.mobile`width: 234px;`}
`
export const StudentStatus = styled.h5`
  margin: 0;
  font-family: 'Lato';

  ${props => (
      props.cardStatus === COMPLETED ||
      props.cardStatus === COMPLETED_NOT_REPEATABLE
    ) && css`
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #BECCFF;
  `}

  ${props => (
    props.cardStatus === IN_PRODUCTION ||
    props.cardStatus === HAS_PRE_REQUISITE ||
    props.cardStatus === PREPARE_CALCULUS
  ) && css`
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #CCD4D8;
  `}

  ${props => (
    props.cardStatus !== IN_PRODUCTION &&
    props.cardStatus !== COMPLETED &&
    props.cardStatus !== COMPLETED_NOT_REPEATABLE &&
    props.cardStatus !== HAS_PRE_REQUISITE &&
    props.cardStatus !== PREPARE_CALCULUS
  ) && css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
  `}
`
export const Chevrons = styled(ChevronsSVG)`
  margin-left: auto;
`

export const CollapseWrapper = styled.div`
  ${({ open }) => open && `
  `};
`

export const ViewMore = styled.p`
  text-transform: uppercase;
  cursor: pointer;
  ${font14x17x700}

  & > img {
    margin-right: 12px;
    margin-bottom: 4px;
  }
`
