import React, { useEffect, useState, useRef, useContext } from 'react'
import './css/RegistrationForm.css'
import * as typeformEmbed from '@typeform/embed'
import { AppContext } from '../ContextProvider/ContextProvider'
import { useUserStore } from '@outlier-org/lst-auth-react'

function RegistrationForm () {
  const user = useUserStore((state) => state.user) || {}
  const { studentId } = user
  const { areAllCoursesProfCert } = useContext(AppContext)
  const [isError, setIsError] = useState(false)
  const formRef = useRef(null)

  const formId = areAllCoursesProfCert ? 'YXTSsk6L' : 'BOqMe0Tm'

  useEffect(() => {
    if (!formRef.current) return
    const embedForm = async () => {
      if (!studentId) setIsError(true)
      if (studentId) {
        const encodedStudentId = encodeURIComponent(studentId)
        typeformEmbed.makeWidget(
          formRef.current,
          `https://form.typeform.com/to/${formId}?student_id=${encodedStudentId}`,
          {
            transferableUrlParameters: ['student_id'],
            onSubmit: () => {
              window.location.reload()
            }
          }
        )
      }
    }

    embedForm()
  }, [formRef, studentId, formId])

  if (isError) {
    return (
      <p className='typeform-error' data-testid='typeform-error'>
        The form didn't load correctly. Please refresh the page and try again.
        If the problem persists, reach out to us at{' '}
        <a href='mailto:success@outlier.org'> success@outlier.org.</a>{' '}
      </p>
    )
  }

  return (
    <>
      <h2 className='registration-form__header'>Registration</h2>
      <div
        id='embedded-typeform'
        ref={formRef}
        data-testid='embedded-typeform'
      />
    </>
  )
}

RegistrationForm.displayName = 'RegistrationForm'
export default RegistrationForm
