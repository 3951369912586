import React, { useContext } from 'react'
import find from 'lodash/find'
import { AppContext } from '../ContextProvider/ContextProvider'
import WidgetState from './WidgetState'
import { Separator } from './style'
import {
  completedFirstSection,
  completedOrientation,
  getFirstSectionUrl,
  getOrientationChapterSectionUrl
} from '../../utilities/chapterUtils'
import { CHAPTER_TYPE } from '../../Constants/courseCard'
import { getLatestCohort } from '../../utilities/course'
import { AUDIT, WITHDRAW } from '../../Constants/studentStatus'
import WritingAssignmentsCard from './WritingAssignments/WritingAssignmentsCard'
import {
  progressBeforeCohortStart
} from '../../utilities/cohort'
import ActivityInProgressAndCompleted from './ActivityInProgressAndCompleted'
import config from '../../config'
import ExamCard from './ExamCard'
import has from 'lodash/has'
import { useUserStore } from '@outlier-org/lst-auth-react'

const InProgressCard = ({
  course
}) => {
  let { id, chapters, cohort } = course
  const { coursesProgress } = useContext(AppContext)
  const user = useUserStore((state) => state.user) || {}
  const { email: studentEmail } = user
  cohort = cohort[0] || cohort
  const courseProgress = find(coursesProgress, { courseId: id })
  if (!courseProgress) return null
  const { studentProgress } = courseProgress
  if (!studentProgress) return null

  const { studentAnswers } = studentProgress

  const activityNotYetStarted = !Object.keys(studentAnswers).length

  const isOrientationCompleted = completedOrientation(course, studentProgress)

  const hasProgressBeforeCohortStart =
    progressBeforeCohortStart(cohort, courseProgress)

  const isFirstSectionCompleted = completedFirstSection(course, studentProgress)
  const orientationSectionUrl = getOrientationChapterSectionUrl(course)
  const sectionUrl = getFirstSectionUrl(course)

  const readyToBeginGuesswork = sectionUrl && activityNotYetStarted
  const latestCohort = getLatestCohort(course)
  const noAssessments = latestCohort &&
    [AUDIT, WITHDRAW].includes(latestCohort.studentStatus)
  let { milestones: writingAssignmentsData } = latestCohort || {}

  const dismissedAssignments =
    localStorage.getItem(`${studentEmail}_dismissedAssignments`)
      ? JSON.parse(
        localStorage.getItem(`${studentEmail}_dismissedAssignments`)
      )
      : []

  if (writingAssignmentsData) {
    writingAssignmentsData = writingAssignmentsData.filter(
      assignment => !dismissedAssignments.includes(assignment.id)
    )
  }

  const dismissAssignments = assignmentId => {
    if (!dismissedAssignments.includes(assignmentId)) {
      dismissedAssignments.push(assignmentId)
    }
    localStorage.setItem(
      `${studentEmail}_dismissedAssignments`,
      JSON.stringify(dismissedAssignments)
    )
  }

  const getSecondSectionUrl = () => {
    if (!chapters || chapters.length === 0) return

    const { CHAPTER } = CHAPTER_TYPE
    const filteredChapters =
      chapters.filter(chapter => chapter.type === CHAPTER)

    if (!filteredChapters.length) return

    const { courseBaseUrlById } = config
    const courseBaseUrl = courseBaseUrlById(id)
    const firstSection = filteredChapters[0].sections[1]
    const { section_uuid: sectionUuid } = firstSection

    return `${courseBaseUrl}/#/${sectionUuid}`
  }

  let stateData = {}

  if (readyToBeginGuesswork) {
    stateData = {
      description: `When you’re ready to begin coursework, 
          let’s start with Section 1.1 Guesswork.`,
      primaryBtnTxt: 'Start',
      primaryBtnFunction: () => { window.location.href = sectionUrl }
    }
  }

  const firstSectionCompleted =
    hasProgressBeforeCohortStart && isFirstSectionCompleted
  if (firstSectionCompleted) {
    stateData = {
      description: `Great job completing Section 1.1 before the cohort began!
       Let’s continue with Section 1.2.`,
      primaryBtnTxt: 'Start',
      primaryBtnFunction: () => {
        const secondSectionUrl = getSecondSectionUrl()
        window.location.href = secondSectionUrl
      }
    }
  }

  const { EXAM } = CHAPTER_TYPE
  const examsArray = chapters.filter(chapter => chapter.type === EXAM)
  const examContainQuestions = has(examsArray[0], 'exams.Question[0]')

  return (
    <div data-testid='in-progress-card'>
      {orientationSectionUrl && !isOrientationCompleted &&
        <div data-testid='start-orientation-message'>
          <WidgetState
            description='Start with Orientation to learn how to succeed in this course.'
            primaryBtnTxt='Start'
            primaryBtnFunction={() => {
              window.location.href = orientationSectionUrl
            }}
          />
          <Separator />
        </div>}
      {(readyToBeginGuesswork || firstSectionCompleted) &&
        <WidgetState
          {...stateData}
        />}
      {!firstSectionCompleted &&
        <ActivityInProgressAndCompleted
          courseProgress={courseProgress}
          course={course}
        />}
      {writingAssignmentsData && !noAssessments &&
      (
        <div data-testid='writing-assignment-card'>
          <WritingAssignmentsCard
            course={course}
            courseProgress={courseProgress}
            assignmentsData={writingAssignmentsData}
            dismissAssignments={dismissAssignments}
          />
        </div>
      )}
      {examContainQuestions &&
        <div data-testid='exam-card'>
          <ExamCard
            course={course}
            examsArray={examsArray}
            studentProgress={studentProgress}
          />
        </div>}
    </div>
  )
}

export default InProgressCard
