import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import media from '../../../mediaQueries'

export const ContentContainer = styled(Container)`
margin-top: 64px;
${media.desktop`
  padding: 85px;
`}
${media.mobile`
  ${props => (props.$hasPracExerciseFlag && 'margin-top: 85px;')}
  padding: 24px;
`};

@media (min-width: 992px) {
  max-width: 816px; /* Max.width of content should be 786px. Additional 30px adjustement to compensate margins of wrappers inside*/
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 576px) and (max-width: 768px) {
  padding-right: ${props => props.padding};
  padding-left: ${props => props.padding};
  max-width: 768px !important;
}
`

export const ContentContainerCustom = styled.div`
  margin-top: 64px;
  ${media.mobile`
    ${props => (props.$hasPracExerciseFlag && 'margin-top: 85px;')}}
  `}
`
