import styled, { createGlobalStyle } from 'styled-components'
import { Toast } from 'react-bootstrap'
import media from '../../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  #content.container {
    margin-top: 0;
  }
`

export const Header = styled.header`
  background: #000000;
  z-index: 9;
  transition: top 0.6s;
  position: fixed;
  width: 100%;
  top: ${props => (props.showHeader ? '0' : '-85px')};
`
export const HeaderContainer = styled.div`
 padding: 0 24px;
`

export const Logo = styled.a`
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  color: #ffffff;
  text-decoration: none;
  vertical-align: middle;
  display: flex;
  align-items: center;
`

export const LogoImage = styled.img`
  vertical-align: middle;
  border-style: none;
  width: 84.4px;
  height: 20px;
`

export const Nav = styled.nav`
  height: 64px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  
  @media (min-width: 576px) {
    flex-flow: row nowrap;
  }
`

export const HamBurger = styled.div`
  align-items: center;
  flex-grow: 0;
  margin-left: .25rem!important;

  @media (min-width: 300px) {
    display: flex;
    flex-basis: auto;
  }
`

export const IconContainer = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: ${props =>
    props.iconRightMargin ? `${props.iconRightMargin}px` : ''};
  margin-left: ${props =>
    props.iconLeftMargin ? `${props.iconLeftMargin}px` : ''};
`

export const IconImage = styled.img`
  display: inline-block;
`

export const NavDropdown = styled.div`
  position: relative;
  padding-bottom: 3px;
  max-height: ${props =>
    props.maxHeight ? `${props.maxHeight}px` : ''};
`

export const DropdownMenu = styled.div`
  z-index: 1050;
  position: relative;

  position: absolute;
  will-change: transform;
  top: -12px;
  left: ${props =>
    props.dropDownOffset ? `${props.dropDownOffset}px` : ''};
  transform: translate3d(-225px, 57px, 0px);
  right: -10px;

  min-width: 252px;
  font-size: calc(12px + var(--font-increase-px));
  line-height: calc(18px + var(--font-increase-px));

  color: #fff;
  text-align: left;
  list-style: none;
  background: #25272B;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0;

  padding: 0;
  margin: .125rem 0 0;
  padding-bottom: ${props =>
    props.paddingBottom ? `${props.paddingBottom}` : ''};

  ul {
    margin-bottom: 0rem;
  }

  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 100%;
    left: auto;
    right: 5%;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-bottom: 0.5em solid #25272B;
    border-right: 0.5em solid transparent;
    border-top: 0;
    border-left: 0.5em solid transparent;
    border-width: .8em;
  }

  ${media.mobile`
    position: fixed !important;
    left: 225px !important;
    right: -225px !important;
    bottom: 0 !important;
    top: -61px !important;
    overflow: hidden !important;
  `}
`

export const CloseDropDownIcon = styled.img`
  cursor: pointer;
  display: none !important;
  ${media.mobile`
    position: fixed;
    right: 17.5px;
    top: 17px;
    display: inline-block !important;
  `}
`

export const DropdownMenuTitle = styled.p`
  text-transform: uppercase;
  color: #C1CFD9;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase; 
  padding: 0;
  margin: 16px 20px;

  display: flex;
  justify-content: space-between;

  & ul {
    margin-bottom: 0rem;
  }
`

export const DropdownMenuItem = styled.a`
  padding: 8px 20px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #525252;
    font-size: calc(12px + var(--font-increase-px));
    text-decoration: none;
  }
`

export const DropdownMenuItemIcon = styled.svg`
  fill: #C1CFD9;
`

export const DropdownMenuItemLabel = styled.span`
  padding-left: 1rem;
`

export const MailContainer = styled.div`
  padding: 0 15px 0px 0;
  ${props => (props.$hasPracExerciseFlag && 'padding-right: 24px;')}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  text-align: right;
`

export const BannerWrapper = styled.div`
  margin-top: 65px;
  padding: 11.95px 93px 13.66px 93px;
  background: #2C3339;
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  ${media.desktop`padding: 10px 145px;`}
  ${media.tablet`padding: 15px 110px;`}
  ${media.mobile`padding: 10px 35px;`}

  a {
    text-decoration: underline;
  }
`

export const Bold = styled.div`
  font-weight: bold;
`

export const CloseButton = styled.span`
  position: absolute;
  top: 29px;
  right: 24px;
  cursor: pointer;
  mask: url(/images/icons/icon-cross-md.svg) no-repeat 50% 50%;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
`

export const ChecklistIcon = styled.img`
  margin-right: 24px;
  margin-left: 10px;
  cursor: pointer;
  width: 17.5px;
  height: 20px;
  transition: 0.5s;
  &:hover {
    filter: opacity(0.5);
  }
`

export const NotificationHeader = styled.div`
  font-weight: bold;
  padding: 24px 12px 12px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #c1cfd9 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const NotificationDetails = styled.div`
  padding: 24px 12px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  :last-child {
    border-bottom: none;
  }
`

export const NotificationHeadline = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 6px;
  font-weight: normal;
`

export const NotificationText = styled(NotificationHeadline)`
  margin-bottom: 12px;
  font-weight: 300;
`

export const NotificationAction = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8;
  cursor: pointer;
  margin-bottom: 12px;
  margin-right: 24px;
`

export const NotificationCourses = styled.div`
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b1bfc5;
`

export const NotificationTimeStamp = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.3);
`

export const NotificationDetailsWrapper = styled.div`
  overflow-y: scroll;
  overscroll-behavior: contain;
  height: 394px;
  /* background styles to add top and bottom shadow on scroll 
  while hiding at the end of scroll */
  background: linear-gradient(#25272b 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #25272b 70%) 0 100%,
    linear-gradient(180deg, #1b1818 0%, #25272b 100%),
    linear-gradient(0deg, #212121 0%, #25272b 100%) 0 100%;
  background: linear-gradient(#25272b 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #25272b 70%) 0 100%,
    linear-gradient(180deg, #1b1818 0%, #25272b 100%),
    linear-gradient(0deg, #212121 0%, #25272b 100%) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 50px, 100% 50px, 100% 10px, 100% 65px;
  background-attachment: local, local, scroll, scroll;

  ${media.mobile`
    max-height: calc(100vh - 53px);
  `}

  ::-webkit-scrollbar {
    width: 3px;
    scroll-margin-top: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #3b3d40;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
export const BrandWrap = styled.span`
  background: #191631;
  border-radius: 3px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #969BFF;
  padding:4px 12px;
  margin-left:8px;
  vertical-align: middle;
`

export const TokenToast = styled(Toast)`
  max-width: 425px;
  position: absolute;
  top: 16px;
  right: 103px;
  background-color: rgba(0,0,0,0);
  backdrop-filter: unset;
  border: unset;
  border-radius: 5px;
  box-shadow: unset;

  ${media.mobile`
    right:15px;
    left: 15px;
 `}
`

export const TokenToastHeader = styled(Toast.Header)`
  background-color: #25272B;
  border-bottom: 0;
  border-radius: 5px 0 5px 5px;
  padding: 24px;

  div {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    padding-right: 24px;
  }

  button.close {
    color: #FFF;
    font-size: 24px;
    opacity: 1;
    text-shadow: unset;
  }
`
