import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import AppGuard from './Components/AppGuard/AppGuard'
import { AuthProvider } from '@outlier-org/lst-auth-react'
import ContextProvider from './Components/ContextProvider/ContextProvider'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { publicRoutes } from './utilities/authUtils'
import api from './api'

const {
  REACT_APP_ID: appId,
  REACT_APP_SSO_AUTH_SAPI_BASE_URL: authGatewayBaseUrl,
  REACT_APP_SSO_AUTH_CONTEXT_ID: authContextId,
  REACT_APP_SSO_AUTH_GATEWAY_CLIENT_ID: authGatewayClientId,
  REACT_APP_SSO_BASE_URL: ssoBaseUrl,
  REACT_APP_SSO_RBS_TOKEN_GRANT_TYPE: rbsTokenGrantType
} = process.env

/**
 * Configuration object for authentication.
 *
 * @typedef {Object} AuthConfig
 * @property {string} appId - The application ID, defaults to 'student-dashboard'.
 * @property {string} authGatewayBaseUrl - The base URL for the authentication gateway.
 * @property {string} authContextId - Unique ID from the dashboard.
 * @property {string} authGatewayClientId - Client ID for the authentication gateway, to be confirmed with devops.
 * @property {boolean} useUserRoleAsRbsScope - Flag to use user role as RBS scope.
 * @property {string} ssoBaseUrl - The base URL for single sign-on.
 * @property {string} rbsTokenGrantType - The grant type for RBS token.
 * @property {string} authCallbackRoute - The route for authentication callback, defaults to '/'.
 * @property {boolean} includeLicensedProducts - Flag to include licensed products, defaults to false.
 * @property {boolean} includePolicy - Flag to include policy, defaults to true.
 * @property {boolean} redirectToCustomError - Flag to redirect to custom error page, defaults to false.
 * @property {string} serviceUrl - The service URL, defaults to the current window location origin.
 * @property {Array<string>} publicRoutes - List of public routes.
 * @property {Function} onPostAuth - Callback function to handle post-authentication.
 */
export const lstAuthConfig = {
  appId: appId || 'student-dashboard',
  authGatewayBaseUrl,
  authContextId, // Unique Id - from dashboard
  authGatewayClientId, // Need to check with devops
  useUserRoleAsRbsScope: true,
  ssoBaseUrl,
  rbsTokenGrantType,
  authCallbackRoute: '/',
  includeLicensedProducts: false,
  includePolicy: true,
  redirectToCustomError: false,
  serviceUrl: window.location.origin,
  publicRoutes,
  onPostAuth: api.handlePostAuthCallback
}

ReactDOM.render(
  <ErrorBoundary>
    <Router>
      <AuthProvider initialConfig={lstAuthConfig}>
        <AppGuard>
          <ContextProvider>
            <App />
          </ContextProvider>
        </AppGuard>
      </AuthProvider>
    </Router>
  </ErrorBoundary>
  , document.getElementById('root'))

serviceWorkerRegistration.register()
